import snakecaseKeys from 'snakecase-keys';

import { APIService } from 'services/api-service';
import {
  PatientAllergyListResponse,
  PatientOtherMedicationResponse,
} from 'types/patient';
import {
  PatientMedication,
  PatientPrescription,
  PrescribeMedicationBody,
} from 'types/patient/prescription';

import {
  GetOrderInfo,
  GetPatientsHormonalEffectResponse,
  GetPatientsTodosResponse,
  NCStatusDetails,
  OrderInfo,
  OrderPatientRefillBody,
  PatientNS,
  PatientPrescriptionRefillResponse,
  UpdateShippingAddressBody,
} from './types';

export class PatientService extends APIService {
  static PATIENT_INVITE_URL = `${this.API_PREFIX_URL}/invites/patient`;
  static Invite = ({
    email,
    name,
    phone,
    preferredShippingType,
    preferredBillingType,
    providerId,
    alreadyOnHormone,
    noQuestionnaire,
    optInSms,
    practiceAddressId,
    practiceId,
  }: PatientNS.PatientInviteBody) => {
    const url = new URL(`${this.PATIENT_INVITE_URL}`);
    url.searchParams.append('practice_id', String(practiceId));
    url.searchParams.append('practice_address_id', String(practiceAddressId));

    return this.post(
      url.toString(),
      {
        email,
        name,
        preferredShippingType,
        preferredBillingType,
        providerId,
        isOnHormones: !!alreadyOnHormone,
        isQuestionaireRequired: !noQuestionnaire,
        phone,
        optInSms: !!optInSms,
      },
      {
        withAuthToken: true,
      }
    );
  };

  static GET_PATIENTS = `${this.API_PREFIX_URL}/provider/patients?size=10`;
  static getPatients = async ({
    page,
    search,
    archived,
  }: PatientNS.GetPatientsBody) =>
    this.get<PatientNS.GetPatientsResponse>(
      `${
        PatientService.GET_PATIENTS
      }&is_active=${!archived}&page=${page}&search=${search}`,
      {
        withAuthToken: true,
      }
    );

  static GET_PATIENTS_V2 = `${this.API_PREFIX_URL}/v2/provider/patients`;
  static getPatientsV2 = async ({
    page,
    search,
    size = 10,
    isTodoCompleted = true,
    archived = false,
  }: PatientNS.GetPatientsBody) => {
    const url = new URL(PatientService.GET_PATIENTS_V2);

    if (isTodoCompleted) {
      url.searchParams.append('is_todo_completed', 'True');
    } else {
      url.searchParams.append('is_todo_completed', 'False');
    }

    if (archived) {
      url.searchParams.append('is_active', 'false');
    } else {
      url.searchParams.append('is_active', 'true');
    }

    url.searchParams.append('page', String(page));
    url.searchParams.append('size', String(size));
    url.searchParams.append('order_by', String('-created_at'));

    if (search) {
      url.searchParams.append('search', String(search));
    }

    return this.get<PatientNS.GetPatientsResponse>(url.toString(), {
      withAuthToken: true,
    });
  };
  static GET_INVITED_PATIENTS = `${this.API_PREFIX_URL}/invites/`;
  static getInvitedPatients = async ({
    page,
    size,
  }: PatientNS.GetInvitedPatientsBody) => {
    const url = new URL(PatientService.GET_INVITED_PATIENTS);

    url.searchParams.append('page', String(page));
    url.searchParams.append('size', String(size));

    return this.get<PatientNS.GetInvitedPatientsResponse>(url.toString(), {
      withAuthToken: true,
    });
  };
  static RESEND_INVITE = `${this.API_PREFIX_URL}/invites/resend`;
  static resendInvite = async ({
    id,
    name,
  }: PatientNS.ResendInvitePatientBody) =>
    this.put(
      `${PatientService.RESEND_INVITE}/${id}`,
      { name },
      {
        withAuthToken: true,
      }
    );

  static DELETE_INVITE = `${this.API_PREFIX_URL}/invites`;
  static deleteInvite = async ({ id }: PatientNS.DeleteInvitePatientBody) =>
    this.delete(
      `${PatientService.DELETE_INVITE}/${id}`,

      {
        withAuthToken: true,
      }
    );

  static GET_PATIENT_DETAILS = `${this.API_PREFIX_URL}/provider/patients`;
  static getPatientDetails = async (id: string) =>
    this.get<PatientNS.GetPatientDetailsResponse>(
      `${PatientService.GET_PATIENT_DETAILS}/${id}`,
      {
        withAuthToken: true,
      }
    );

  static GET_PATIENT_MEDICATION = `${this.API_PREFIX_URL}/medication/`;
  static getPatientMedication = async (gender: string) =>
    this.get<PatientMedication[]>(
      `${PatientService.GET_PATIENT_MEDICATION}?gender=${gender}`,
      {
        withAuthToken: true,
      }
    );

  static GET_PATIENT_PRESCRIPTIONS = `${this.API_PREFIX_URL}/medication/prescriptions`;
  static getPatientPrescription = async ({
    category,
    id,
  }: PatientNS.GetPatientPrescriptionBody) => {
    const url = new URL(
      `${PatientService.GET_PATIENT_PRESCRIPTIONS}?patient_id=${id}${
        category ? `&category=${category}` : ''
      }`
    );

    return this.get<PatientPrescription[]>(url.toString(), {
      withAuthToken: true,
    });
  };

  static PRESCRIBE_MEDICATION = `${this.API_PREFIX_URL}/medication/prescriptions`;
  static prescribeMedication = async ({
    patientId,
    medications,
    titrationType,
    excludedHormones,
    preferredShipping,
    billingCycle,
    hormoneDiagnosisCodeId,
    thyroidDiagnosisCodeId,
    orderNote,
  }: PrescribeMedicationBody) => {
    const prescriptions = medications.map((medication) => ({
      ...medication,
      delivery_method_and_sig: JSON.stringify(
        snakecaseKeys(medication.deliveryMethodAndSig || {})
      ),
      dtd: JSON.stringify(medication.dtd),
      ratio: JSON.stringify(medication.ratio),
      strength: JSON.stringify(medication.strength),
    }));

    return this.post<{ order: OrderInfo }>(
      `${PatientService.PRESCRIBE_MEDICATION}/${patientId}/v1`,
      {
        prescriptions,
        titrationType,
        excludedHormones,
        preferredShipping,
        billingCycle,
        hormoneDiagnosisCodeId,
        thyroidDiagnosisCodeId,
        orderNote,
      },
      {
        withAuthToken: true,
      }
    );
  };

  static ADD_NOTE_URL = `${this.API_PREFIX_URL}/patient/notes/`;
  static addPatientNote = ({ id, note }: PatientNS.AddNoteBody) =>
    this.post(
      `${PatientService.ADD_NOTE_URL}${id}`,
      {
        note,
      },
      {
        withAuthToken: true,
      }
    );

  static GET_PATIENT_NOTES = `${this.API_PREFIX_URL}/patient/notes/`;
  static getPatientNotes = async ({
    id,
    page = 1,
  }: {
    id: string;
    page?: number;
  }) => {
    const url = new URL(this.GET_PATIENT_NOTES + id);
    url.searchParams.append('page', `${page}`);
    url.searchParams.append('size', `${10}`);

    return this.get<PatientNS.GetNotesResponse>(url.toString(), {
      withAuthToken: true,
    });
  };
  static GET_DIAGNOSIS_CODES_URL = `${this.API_PREFIX_URL}/medication/diagnosis-codes`;
  static getDiagnosisCodes = async ({
    gender,
    type,
  }: {
    gender: string;
    type: string;
  }) => {
    const url = new URL(`${this.GET_DIAGNOSIS_CODES_URL}/${gender}/${type}`);

    return this.get<PatientNS.GetDiagnosisCodeResponse>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_PATIENT_DIAGNOSIS_CODES_URL = `${this.API_PREFIX_URL}/medication/patient-diagnosis`;
  static getPatientDiagnosisCodes = async (id: string) => {
    const url = new URL(`${this.GET_PATIENT_DIAGNOSIS_CODES_URL}/${id}`);

    return this.get<PatientNS.GetPatientDiagnosisCodesType>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_PATIENT_ALLERGIES_MEDICATION_URL = `${this.API_PREFIX_URL}/provider/patients`;
  static getPatientAllergiesMedication = async (id: string) => {
    const url = new URL(
      `${this.GET_PATIENT_ALLERGIES_MEDICATION_URL}/${id}/allergies-medications`
    );

    return this.get<PatientNS.GetPatientAllergiesMedicationType>(
      url.toString(),
      {
        withAuthToken: true,
      }
    );
  };

  static updatePatientAllergiesMedication = async ({
    id,
    patientKnownAllergies,
    patientOtherMedications,
    patientOtherMedicationsNc,
    patientKnownAllergiesNc,
  }: PatientNS.UpdatePatientAllergiesMedication) => {
    const url = new URL(
      `${this.GET_PATIENT_ALLERGIES_MEDICATION_URL}/${id}/allergies-medications`
    );

    return this.put(
      url.toString(),
      {
        patientKnownAllergies,
        patientOtherMedications,
        patientKnownAllergiesNc: JSON.stringify(patientKnownAllergiesNc),
        patientOtherMedicationsNc: JSON.stringify(patientOtherMedicationsNc),
      },
      {
        withAuthToken: true,
      }
    );
  };

  static GET_PATIENT_ORDERS_URL = `${this.API_PREFIX_URL}/orders/`;
  static getPatientOrders = async ({
    id,
    page,
    type,
  }: PatientNS.GetPatientOrdersBody) => {
    const url = new URL(this.GET_PATIENT_ORDERS_URL);
    url.searchParams.append('page', String(page));
    url.searchParams.append('size', String(10));
    url.searchParams.append('patient_id', id);
    if (type === 'fulfilled') {
      url.searchParams.append('status__in', 'completed');
    }
    if (type === 'ongoing') {
      url.searchParams.append(
        'status__not_in',
        'canceled,confirmed_receipt,completed'
      );
    }

    return this.get<GetOrderInfo>(url.toString(), {
      withAuthToken: true,
    });
  };

  static CANCEL_PATIENT_ORDER_URL = `${this.API_PREFIX_URL}/orders/`;
  static cancelPatientOrder = async ({
    patientId,
    orderId,
  }: PatientNS.DeletePatientOrderBody) => {
    const url = new URL(
      `${this.CANCEL_PATIENT_ORDER_URL}${orderId}/cancel?patient_id=${patientId}`
    );

    return this.put(
      url.toString(),
      {},
      {
        withAuthToken: true,
      }
    );
  };
  static GET_PATIENT_ALLERGIES_LIST_URL = `${this.API_PREFIX_URL}/medication/newcrop/allergies/search`;
  static getPatientAllergiesList = async ({
    search,
    page,
  }: {
    search: string;
    page: number;
  }) => {
    const url = new URL(`${this.GET_PATIENT_ALLERGIES_LIST_URL}`);

    if (page) {
      url.searchParams.append('page', String(page));
    }

    if (search) {
      url.searchParams.append('query', search);
    }

    return this.get<PatientAllergyListResponse>(url.toString(), {
      withAuthToken: true,
    }).then((response) => ({
      ...response,
      data: {
        items: response.data.items,
        page: response.data.page,
        // eslint-disable-next-line
        // @ts-ignore
        size: 10,
        // eslint-disable-next-line
        // @ts-ignore
        total: response.data.found,
      },
    }));
  };

  static GET_PATIENT_OTHER_MEDICATION_LIST_URL = `${this.API_PREFIX_URL}/medication/newcrop/medications/search`;
  static getPatientOtherMedicationList = async ({
    search,
    page,
  }: {
    search: string;
    page: number;
  }) => {
    const url = new URL(`${this.GET_PATIENT_OTHER_MEDICATION_LIST_URL}`);

    if (page) {
      url.searchParams.append('page', String(page));
    }

    if (search) {
      url.searchParams.append('query', search);
    }

    return this.get<PatientOtherMedicationResponse>(url.toString(), {
      withAuthToken: true,
    }).then((response) => ({
      ...response,
      data: {
        items: response.data.items,
        page: response.data.page,
        // eslint-disable-next-line
        // @ts-ignore
        size: 10,
        // eslint-disable-next-line
        // @ts-ignore
        total: response.data.found,
      },
    }));
  };

  static PATIENT_SHIPPING_ADDRESS_URL = `${this.API_PREFIX_URL}/profile/patient/shipping`;
  static updatePatientShippingAddress = ({
    patientId,
    address,
  }: UpdateShippingAddressBody) => {
    const url = new URL(this.PATIENT_SHIPPING_ADDRESS_URL);
    url.searchParams.append('patient_id', patientId);

    return this.put(
      url.toString(),
      {
        ...address,
        address_line_1: address.addressLine1,
        address_line_2: address.addressLine2,
      },
      {
        withAuthToken: true,
      }
    );
  };

  static UPDATE_PATIENT_DETAILS_URL = `${this.API_PREFIX_URL}/profile/`;
  static updatePatientDetails = ({
    firstName,
    lastName,
    middleName,
    email,
    patientID,
    phone,
  }: PatientNS.UpdatePatientDetailsBody) => {
    const url = new URL(PatientService.UPDATE_PATIENT_DETAILS_URL);
    url.searchParams.append('user_id', patientID);

    return this.put(
      url.toString(),
      {
        firstName,
        lastName,
        middleName,
        email,
        phone,
      },
      {
        withAuthToken: true,
      }
    );
  };

  static UPDATE_PATIENT_DOB_URL = `${this.API_PREFIX_URL}/profile/patient/status`;
  static updateDobDetails = ({
    dob,
    patientID,
  }: PatientNS.UpdatePatientDOBBody) => {
    const url = new URL(PatientService.UPDATE_PATIENT_DOB_URL);
    url.searchParams.append('patient_id', patientID);

    return this.put(
      url.toString(),
      {
        dob,
      },
      {
        withAuthToken: true,
      }
    );
  };

  static GET_PATIENT_REFILL = `${this.API_PREFIX_URL}/v2/orders/refills`;
  static getPatientRefill = async (patientId: string) =>
    this.get<PatientPrescriptionRefillResponse>(
      `${PatientService.GET_PATIENT_REFILL}?patient_id=${patientId}`,
      {
        withAuthToken: true,
      }
    );

  static PATIENT_REFILL_URL = `${this.API_PREFIX_URL}/v2/orders/refills`;
  static orderRefill = ({
    patientID,
    refillProducts,
    shippingProduct,
    refillOrderNote,
  }: OrderPatientRefillBody) => {
    const url = new URL(PatientService.PATIENT_REFILL_URL);
    url.searchParams.append('patient_id', patientID);

    return this.post(
      url.toString(),
      {
        refillProducts,
        shippingProduct,
        refillOrderNote,
      },
      {
        withAuthToken: true,
      }
    );
  };

  static PATIENT_STATUS_URL = `${this.API_PREFIX_URL}/provider/patients`;
  static switchUserActive = async ({
    id,
    isActive,
  }: {
    id: string;
    isActive: boolean;
  }) =>
    this.put(
      `${this.PATIENT_STATUS_URL}/${id}/account-status`,
      { isActive },
      {
        withAuthToken: true,
      }
    );

  static GET_PATIENT_TODOS = `${this.API_PREFIX_URL}/provider/patients`;
  static getPatientTodos = async (patientId: string) => {
    const url = new URL(`${this.GET_PATIENT_TODOS}/${patientId}/to-dos`);

    return this.get<GetPatientsTodosResponse>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_PATIENT_HORMONAL_EFFECT = `${this.API_PREFIX_URL}/provider/patients`;
  static getPatientHormonalEffect = async (patientId: string) => {
    const url = new URL(
      `${this.GET_PATIENT_HORMONAL_EFFECT}/${patientId}/hormonal-effect-over-time`
    );

    return this.get<GetPatientsHormonalEffectResponse[]>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_PATIENT_CURRENT_HORMONAL_STATE = `${this.API_PREFIX_URL}/provider/patients`;
  static getPatientCurrentHormonalState = async (patientId: string) => {
    const url = new URL(
      `${this.GET_PATIENT_CURRENT_HORMONAL_STATE}/${patientId}/current-hormonal-state`
    );

    return this.get<GetPatientsHormonalEffectResponse>(url.toString(), {
      withAuthToken: true,
    });
  };

  static GET_ORDER_PRESCRIPTION_NC_STATUS = `${this.API_PREFIX_URL}/orders/nc_status`;
  static getOrderPrescriptionNCStatus = async (prescriptionId: string) => {
    const url = new URL(
      `${this.GET_ORDER_PRESCRIPTION_NC_STATUS}/${prescriptionId}`
    );

    return this.get<NCStatusDetails>(url.toString(), {
      withAuthToken: true,
    });
  };
}
