import React, { useEffect, useRef, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { BiEdit } from 'react-icons/bi';
import { IoTrash } from 'react-icons/io5';

import { Chip } from 'components/common/Chip/Chip';
import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { Typography } from 'components/common/Typography/Typography';
import { AddLicenseDeaModal } from 'components/profile/AddLicenseDea/AddLicenseDeaModal';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';
import { AddressType } from 'types/onboard';
import { GetDeaLicenses, SupervisingProvider } from 'types/provider';

type SupervisingProviderProps = {
  supervisingProviders: SupervisingProvider[];
  shippingAddress?: AddressType;
  setSupervisingDoctor: React.Dispatch<
    React.SetStateAction<{
      yes: boolean;
      no: boolean;
    }>
  >;
  supervisingDoctor: {
    yes: boolean;
    no: boolean;
  };
};

export function SupervisingProviderCard({
  supervisingProviders,
  shippingAddress,
  setSupervisingDoctor,
  supervisingDoctor,
}: SupervisingProviderProps) {
  const queryClient = useQueryClient();
  const getDEALicenseQuery = useQuery([QueryKeys.ProviderDEALicenses], () =>
    UserService.getLicensesAndDEA()
  );
  const currentStateData = getDEALicenseQuery.data?.data.find(
    (item) => item.stateId === shippingAddress?.state?.id
  );

  const [deaLicenseData, setDEALicenseData] = useState<
    GetDeaLicenses | undefined
  >();
  const [
    openDeleteSupervisingProviderConfirmDialog,
    setOpenDeleteSupervisingProviderConfirmDialog,
  ] = useState<boolean>(false);
  const [isOpenSupervisingProviderModal, setIsOpenSupervisingProviderModal] =
    useState(false);
  const editingSupervisingProviderIdRef = useRef<string>('');
  const updateDEALicenseMutation = useMutationWithToast(
    UserService.updateProviderDeaAndLicense
  );

  const getStatesQuery = useQuery(
    [QueryKeys.States],
    () => UserService.getStates(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const deleteSupervisingProviderMutation = useMutationWithToast(
    UserService.deleteSupervisingProvider
  );
  const onDeleteSupervisingProvider = () => {
    if (currentStateData) {
      const {
        dea,
        deaExpiry,
        license,
        licenseExpiry,
        stateId,
      }: GetDeaLicenses = currentStateData;

      updateDEALicenseMutation.mutate(
        {
          stateId,
          dea,
          deaExpiry,
          license,
          licenseExpiry,
          supervisingProvider: null,
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries([QueryKeys.ProviderDEALicenses]);
            queryClient.refetchQueries([QueryKeys.UserProfile]).then(() => {
              setOpenDeleteSupervisingProviderConfirmDialog(false);
            });

            setSupervisingDoctor({ yes: false, no: false });
          },
        }
      );
    }
  };

  const matchingSupervisingProvider = () =>
    supervisingProviders.find(
      (sp) => sp.licenseState === shippingAddress?.state?.id
    );

  useEffect(() => {
    if (!matchingSupervisingProvider()?.id) {
      setSupervisingDoctor({ yes: false, no: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingAddress]);

  return (
    <div className=" flex w-full  grow flex-col gap-4 rounded bg-background-main  p-6 ">
      <Typography variant="h4">Supervising Provider</Typography>
      <div className="flex flex-col">
        {matchingSupervisingProvider()?.id ? (
          <li
            key={matchingSupervisingProvider()?.id}
            className="mb-2 grid w-max gap-3 rounded-lg bg-background-light p-3">
            <div className="flex items-center justify-between space-x-5 ">
              <Typography className=" whitespace-nowrap" variant="subtitle2">
                {matchingSupervisingProvider()?.licensedPrescriberFirstName}{' '}
                {matchingSupervisingProvider()?.licensedPrescriberMiddleName}{' '}
                {matchingSupervisingProvider()?.licensedPrescriberLastName}{' '}
                {matchingSupervisingProvider()?.licensedPrescriberSuffix} (#
                <span className="text-primary-light">
                  {matchingSupervisingProvider()?.id}
                </span>
                )
              </Typography>
              <div className="flex space-x-2">
                <BiEdit
                  onClick={() => {
                    setIsOpenSupervisingProviderModal(true);
                    setDEALicenseData(currentStateData);

                    // eslint-disable-next-line no-param-reassign
                    editingSupervisingProviderIdRef.current =
                      matchingSupervisingProvider()?.id ?? '';
                  }}
                  className="cursor-pointer text-2xl text-background-contrastText active:scale-105"
                />
                <IoTrash
                  onClick={() => {
                    // eslint-disable-next-line no-param-reassign
                    editingSupervisingProviderIdRef.current =
                      matchingSupervisingProvider()?.id ?? '';
                    setOpenDeleteSupervisingProviderConfirmDialog(true);
                  }}
                  className="cursor-pointer text-2xl text-error-light active:scale-105"
                />
              </div>
            </div>
            <div className="flex space-x-2">
              <div className="flex flex-col  space-y-2">
                <Typography variant="subtitle2">DEA</Typography>
                <Chip
                  className="w-max"
                  label={`${
                    getStatesQuery.data?.data.find(
                      (s) =>
                        s.id === matchingSupervisingProvider()?.licenseState
                    )?.abbreviation
                  }-${matchingSupervisingProvider()?.dea}`}
                />
              </div>
              <div className="w-max' flex flex-col  space-y-2">
                <Typography variant="subtitle2">License</Typography>
                <Chip
                  className="w-max"
                  label={`${
                    getStatesQuery.data?.data.find(
                      (s) =>
                        s.id === matchingSupervisingProvider()?.licenseState
                    )?.abbreviation
                  }-${matchingSupervisingProvider()?.licenseNumber}`}
                />
              </div>
              <div className="flex flex-col  space-y-2">
                <Typography variant="subtitle2">NPI</Typography>
                <Chip
                  className="w-max"
                  label={matchingSupervisingProvider()?.npi ?? 'Not Available'}
                />
              </div>
            </div>
          </li>
        ) : (
          <div className="flex flex-col gap-2">
            <div className="v flex flex-col gap-4 pb-4">
              {' '}
              <RadioButton
                className="w-1/2"
                onChange={() => {
                  setSupervisingDoctor({ yes: true, no: false });
                  if (currentStateData?.stateId) {
                    setDEALicenseData({
                      ...currentStateData,
                      stateId: shippingAddress?.state?.id ?? '',
                    });
                  } else {
                    setDEALicenseData(currentStateData);
                  }

                  setIsOpenSupervisingProviderModal(true);
                }}
                square
                label={
                  <>
                    Yes, I need a supervising provider for the state of
                    {shippingAddress?.id ? (
                      <Typography className="mx-1" variant="subtitle2">
                        {shippingAddress?.state?.name}.
                      </Typography>
                    ) : (
                      '.'
                    )}
                  </>
                }
                id=""
                checked={supervisingDoctor.yes}
              />{' '}
              <RadioButton
                className="w-1/2"
                onChange={() => {
                  setSupervisingDoctor({ yes: false, no: true });
                }}
                square
                label={
                  <>
                    No, I don&lsquo;t need a supervising provider for the state
                    of
                    {shippingAddress?.id && (
                      <Typography className="mx-1" variant="subtitle2">
                        {shippingAddress?.state?.name}.
                      </Typography>
                    )}
                  </>
                }
                id="k"
                checked={supervisingDoctor.no}
              />{' '}
            </div>
          </div>
        )}
      </div>

      <AddLicenseDeaModal
        showAddModal={isOpenSupervisingProviderModal}
        handleClose={() => {
          setIsOpenSupervisingProviderModal(false);
          setDEALicenseData(undefined);
          setSupervisingDoctor({ yes: false, no: false });
        }}
        isUpdate
        deaLicenseData={deaLicenseData}
        patientStateId={shippingAddress?.state?.id ?? ''}
        isSuperVisingProviderRequiredOnCheckout={!!supervisingDoctor.yes}
      />
      <ConfirmationModal
        closeModal={() => {
          if (
            deleteSupervisingProviderMutation.isLoading ||
            !!queryClient.isFetching([QueryKeys.UserProfile])
          ) {
            return;
          }
          setOpenDeleteSupervisingProviderConfirmDialog(false);
        }}
        classes={{
          confirmBtn: '!bg-error-main',
        }}
        title="Are you sure to delete this supervising provider?"
        confirmText="Delete"
        handleConfirm={onDeleteSupervisingProvider}
        confirmLoading={
          deleteSupervisingProviderMutation.isLoading ||
          !!queryClient.isFetching([QueryKeys.UserProfile])
        }
        isModalOpen={openDeleteSupervisingProviderConfirmDialog}
      />
    </div>
  );
}
