import React from 'react';

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { differenceInYears } from 'date-fns';

import { GMTDate } from 'lib/general/GMT-date';
import { Note } from 'services/patient/types';
import { Gender } from 'types/patient';

import logo from '../../../assets/images/textlogowider.png';

type PatientDetailsType = {
  firstName: string;
  lastName: string;
  dob: string;
  phone: string;
  email: string;
  gender: Gender;
};

type NotesPDFProps = {
  notes?: Note[];
  patientDetails: PatientDetailsType;
};

export function NotesPDF({ notes, patientDetails }: NotesPDFProps) {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#fff',
      padding: 15,
    },
    header: {
      marginTop: 30,
      alignItems: 'center',
      flexDirection: 'row',
      gap: 10,
    },
    logo: {
      height: 50,
    },
    name: {
      fontSize: 30,
      fontWeight: 400,
      color: '#515151',
      fontFamily: 'Helvetica-BoldOblique',
    },
    pharmacyAddress: {
      fontSize: 10,
      color: '#515151',
      fontFamily: 'Helvetica-Oblique',
    },
    divider: {
      borderTop: 1,
      borderTopColor: '#eee',
      marginVertical: 30,
    },
    bodyFontBold: {
      fontSize: 11,
      color: '#414141',
      fontFamily: 'Helvetica',
      fontWeight: 600,
    },
    bodyFontNormal: {
      fontSize: 11,
      color: '#616161',
      fontFamily: 'Helvetica',
      fontWeight: 600,
    },
    roleLabel: {
      fontSize: 14,
      color: '#515151',
      fontFamily: 'Helvetica-Bold',
    },
    headingFont: {
      fontSize: 14,
      color: '#515151',
      fontFamily: 'Helvetica-Bold',
      fontWeight: 600,
    },
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  function PatientDetails({
    firstName,
    lastName,
    dob,
    phone,
    email,

    gender,
  }: PatientDetailsType) {
    return (
      <View style={{ display: 'flex', gap: 8 }}>
        <Text style={styles.roleLabel}>Patient Details</Text>
        <View>
          <Text style={styles.bodyFontBold}>
            {firstName} {lastName},{' '}
            {differenceInYears(new Date(), new Date(dob))},{' '}
            {gender === 'M' ? 'Male' : 'Female'}
          </Text>
          <View style={{ marginTop: 8 }}>
            <Text style={styles.bodyFontNormal}>
              {new GMTDate(dob).getReadableDateInGMT()}
            </Text>
          </View>

          <View style={{ marginTop: 8 }}>
            <Text style={styles.bodyFontNormal}>
              {phone} | {email}
            </Text>
          </View>
        </View>
      </View>
    );
  }

  return (
    <Document pageMode="fullScreen">
      <Page size="A4" style={styles.page}>
        <View
          style={{
            width: '100%',
          }}>
          {' '}
          <View style={styles.header}>
            <Image style={styles.logo} src={logo} />
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
            }}>
            <Text style={[styles.bodyFontBold]}>
              Date:{' '}
              {new GMTDate(new Date().toISOString()).getReadableDateInGMT()}
            </Text>
          </View>
          {/* <View style={[styles.divider, { marginTop: 5 }]} /> */}
          <View style={[styles.divider, { marginVertical: 10 }]} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <PatientDetails
              {...{
                dob: patientDetails?.dob,
                email: patientDetails?.email,
                firstName: patientDetails?.firstName,
                gender: patientDetails.gender,
                lastName: patientDetails?.lastName,
                phone: patientDetails?.phone,
              }}
            />
          </View>
          <View style={styles.divider} />
          <View style={{ display: 'flex', gap: 16 }}>
            <Text style={styles.headingFont}>Note(s)</Text>
            {[...(notes ?? [])].map((note) => (
              <View style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={styles.bodyFontBold}>{note?.note}</Text>{' '}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 6,
                    alignItems: 'center',
                    marginTop: 6,
                  }}>
                  {note?.createdByUser?.pictureUrl ? (
                    <Image
                      style={{ width: 30, height: 30, borderRadius: 50 }}
                      src={note?.createdByUser?.pictureUrl}
                    />
                  ) : (
                    <View
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 30,
                        height: 30,
                        borderRadius: 50,
                        backgroundColor: '#359583',
                      }}>
                      <Text
                        style={[
                          {
                            color: 'white',
                            fontSize: 16,
                            fontFamily: 'Helvetica',
                            fontWeight: 600,
                          },
                        ]}>
                        {note?.createdByUser?.name[0]}
                      </Text>{' '}
                    </View>
                  )}
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text style={[styles.bodyFontBold]}>
                      {note?.createdByUser?.name}
                    </Text>{' '}
                    <Text
                      style={[
                        styles.bodyFontBold,
                        {
                          marginTop: 2,
                        },
                      ]}>
                      {new GMTDate(note?.createdAt).getReadableDateInGMT()}
                    </Text>{' '}
                  </View>
                </View>
                <View
                  style={[
                    styles.divider,
                    {
                      opacity: 0.5,
                    },
                  ]}
                />
              </View>
            ))}
          </View>
          <View style={styles.divider} />
        </View>
      </Page>
    </Document>
  );
}
