/* eslint-disable import/extensions */
import React, { useCallback, useState } from 'react';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Overlay } from 'components/common/Overlay/Overlay';
import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { AddNoteModal } from 'components/patient/notes/AddNoteModal';
import { Note } from 'components/patient/notes/Note';
import { NotesPDF } from 'components/patient/notes/NotesPDF';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { PatientService } from 'services/patient';
import { PatientDetailsContextType } from 'types/patient';

export function PatientNotes() {
  const queryClient = useQueryClient();

  const { id, firstName, lastName, gender, phone, email, dob } =
    useOutletContext<PatientDetailsContextType>();
  const addNoteMutation = useMutationWithToast(PatientService.addPatientNote);
  const [showBiggerImage, setShowBiggerImage] = useState({
    state: false,
    data: '',
  });
  const [addNoteModal, setAddNoteModal] = useState(false);
  const getNotesQuery = usePaginatedQuery(
    QueryKeys.PatientNotes.item(id as string),
    ({ pageParam = 1 }) =>
      PatientService.getPatientNotes({ id, page: pageParam }),
    { enabled: !!id }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addNote = (note: string, clearNote: () => void) => {
    addNoteMutation.mutate(
      { id, note },
      {
        onSuccess: () => {
          queryClient.refetchQueries(QueryKeys.PatientNotes.item(id));
          setAddNoteModal(false);
          clearNote();
        },
      }
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components

  const onNotesScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (getNotesQuery.isFetchingNextPage) return;
      const target = event.currentTarget;

      if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
        getNotesQuery.fetchNextPage();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getNotesQuery.fetchNextPage, getNotesQuery.isFetchingNextPage]
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex h-10 justify-between">
        <Typography variant="h4">Notes</Typography>{' '}
        <div className="flex gap-2">
          {getNotesQuery?.data?.pages[0]?.note && (
            <PDFDownloadLink
              document={
                <NotesPDF
                  patientDetails={{
                    firstName,
                    lastName,
                    gender,
                    email,
                    phone,
                    dob,
                  }}
                  notes={[...(getNotesQuery?.data?.pages ?? [])]}
                />
              }
              fileName={`${firstName}_${lastName}_Notes`}>
              {() => <Button className="!h-10 !py-2">Download Note(s)</Button>}
            </PDFDownloadLink>
          )}
          <Button
            onClick={() => {
              setAddNoteModal(true);
            }}>
            Add Note
          </Button>
        </div>
      </div>

      <div
        className={classNames(
          ' col-span-6 flex h-[31.5rem] flex-col  gap-2 overflow-auto rounded bg-background-main',
          {
            'bg-background-main': !getNotesQuery?.data?.pages[0],
          }
        )}
        onScroll={onNotesScroll}>
        {getNotesQuery?.data?.pages?.map((item) => (
          <Note
            className=" rounded bg-background-light p-3"
            key={item.id}
            note={item}
          />
        ))}
        {!getNotesQuery?.data?.pages[0] && !getNotesQuery.isLoading && (
          <div className="mt-6 flex justify-center">
            {' '}
            <Typography variant="subtitle1"> No records found</Typography>{' '}
          </div>
        )}

        {getNotesQuery.isFetchingNextPage || getNotesQuery.isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner className="  text-primary-main" />
          </div>
        ) : null}
      </div>

      {/* <PDFViewer className="h-[30rem] w-[50rem]">
        <NotesPDF
          patientDetails={{
            firstName,
            lastName,
            gender,
            email,
            phone,
            dob,
          }}
          notes={[...(getNotesQuery?.data?.pages ?? [])]}
        />
      </PDFViewer> */}
      <AddNoteModal
        open={addNoteModal}
        onSubmit={(note, clearNote) => {
          addNote(note, clearNote);
        }}
        onClose={() => {
          setAddNoteModal(false);
        }}
        loading={addNoteMutation.isLoading}
      />
      <Overlay
        onClose={() => {
          setShowBiggerImage({
            state: false,
            data: '',
          });
        }}
        className="!flex !items-center !justify-center"
        open={showBiggerImage.state}>
        {' '}
        <Avatar sizeClass="h-96 w-96 " imgUrl={showBiggerImage.data} />
      </Overlay>
    </div>
  );
}
