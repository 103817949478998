import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { isSameDay } from 'date-fns';
import toast from 'react-hot-toast';
import { useOutletContext, useParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { InfoTooltip } from 'components/common/InfoTooltip/InfoTooltip';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import { PrescriptionDetailsValue } from 'components/patient/AdjustDose/PrescriptionDetailsValue';
import { PrescriptionsDates } from 'components/patient/AdjustDose/PrescriptionsDates';
import { DoseSplitter } from 'components/patient/e-prescribeV2/DoseSplitter';
import { ChangeDoseConfirmationModal } from 'components/patient/Order/ChangeDoseConfirmationModal';
import { PrescriptionDosageHistoryModal } from 'components/patient/PrescriptionDosageHistoryModal/PrescriptionDosageHistoryModal';
import { PrescriptionSlider } from 'components/prescriptions/PrescriptionSlider';
import { TitrationDetails } from 'components/prescriptions/tiration-details/TitrationDetails';
import {
  femaleHormoneRxTypes,
  maleHormoneRxTypes,
} from 'constants/hormone-details-new';
import { QueryKeys } from 'constants/query-keys';
import { useDebounce } from 'hooks/useDebounce';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { GMTDate } from 'lib/general/GMT-date';
import { PatientPrescriptionService } from 'services/prescription';
import { PatientDetailsContextType } from 'types/patient';
import {
  CurrentDoseType,
  PatientPrescription,
  PrescriptionCheckType,
} from 'types/patient/prescription';
import { DoseSplittingMethods, HormoneNames } from 'types/prescriptionTypes';
import { formatDeliveryUnit } from 'utils/common';

import { adjustDoseSkeleton } from '../../../../../components/patient/AdjustDose/helper';

type PrescriptionDetailsPageProps = {
  prescription: PatientPrescription;
};

export function PrescriptionDetailsPage({
  prescription,
}: PrescriptionDetailsPageProps) {
  const queryClient = useQueryClient();
  // const navigate = useNavigate();
  const patientDetails = useOutletContext<PatientDetailsContextType>();
  const { id: patientId } = useParams();
  const [currentDose, setcurrentDose] = useState<number | undefined>(0);
  const debouncedValue = useDebounce<number | undefined>(currentDose, 1000);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [showDosageHistory, setShowDosageHistory] = useState(false);
  const [currentPrescriptionCheckValue, setCurrentPrescriptionCheckValue] =
    useState<PrescriptionCheckType>();
  const [showDoseChangeConfModal, setshowDoseChangeConfModal] = useState(false);

  const [radioButtonLoading, setRadioButtonLoading] = useState(false);

  const [splittedDose, setSplittedDose] = useState<{
    morning: string;
    evening: string;
  }>();

  const [prevSplitType, setPrevSplitType] = useState<DoseSplittingMethods>();

  const [historyDoseCheckValue, setHistoryDoseCheckValue] =
    useState<PrescriptionCheckType>();

  const getPrescriptionCheckMutation = useMutationWithToast(
    (dose: string) =>
      PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          deliveryMethodAndSig: {
            ...prescription.deliveryMethodAndSig,
            dose: {
              ...prescription.deliveryMethodAndSig.minDailyDose,
              value: dose,
            },
          },
          medicationId: prescription.prescriptionToMedication.id,
        },
      }),
    {
      retry: false,

      onSuccess(data) {
        setCurrentPrescriptionCheckValue(() => data.data);
      },
    }
  );
  const updateDoseMutation = useMutation(
    (dose: CurrentDoseType) =>
      PatientPrescriptionService.updateDose({
        dose,
        prescriptionId: prescription.id,
        patientId: patientId ?? '',
      }),
    {
      onSuccess() {
        queryClient.refetchQueries(
          QueryKeys.PatientPrescriptions.listing({
            category: 'current',
            id: patientDetails?.id ?? '',
          })
        );
        queryClient.refetchQueries(
          QueryKeys.PrescriptionDosageHistory.listing({
            patientId: patientId ?? '',
            prescriptionId: prescription.id,
          })
        );
        toast.success('Dose updated successfully');
      },
    }
  );
  const sameDayDosages = prescription?.titrations?.find((t) =>
    isSameDay(new Date(), new GMTDate(t?.date))
  );
  const checkPrescriptionValueCurrentDoseQuery = useQuery(
    [prescription.id, prescription.deliveryMethodAndSig.dose.value, 'current'],
    () =>
      PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          deliveryMethodAndSig: {
            ...prescription.deliveryMethodAndSig,
            dose: {
              ...prescription.deliveryMethodAndSig.minDailyDose,
              value: prescription.deliveryMethodAndSig.currentDose
                ? (
                    Number(
                      prescription.deliveryMethodAndSig.currentDose.morning
                        .value
                    ) +
                    Number(
                      prescription.deliveryMethodAndSig.currentDose.evening
                        .value
                    )
                  ).toFixed(2)
                : prescription?.titrationType
                ? (
                    Number(sameDayDosages?.morningDose?.value ?? 0) +
                    Number(sameDayDosages?.eveningDose?.value ?? 0)
                  ).toString()
                : prescription.deliveryMethodAndSig.minDailyDose.value,
            },
          },
          medicationId: prescription.prescriptionToMedication.id,
        },
      }),
    {
      enabled:
        !!patientId ||
        !!prescription.id ||
        !!prescription.deliveryMethodAndSig?.currentDose,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const checkPrescriptionMaxDoseValueQuery = useQuery(
    [prescription.id, prescription.deliveryMethodAndSig.dose.value, 'max'],
    () =>
      PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          medicationId: prescription.prescriptionToMedication.id,
        },
      }),
    {
      enabled:
        !!patientId ||
        !!prescription.id ||
        !!prescription.deliveryMethodAndSig.dose.value,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const checkPrescriptionMinDoseValueQuery = useQuery(
    [
      prescription.id,
      prescription.deliveryMethodAndSig?.minDailyDose?.value,
      'min',
    ],
    () =>
      PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          deliveryMethodAndSig: {
            ...prescription.deliveryMethodAndSig,
            dose: { ...prescription.deliveryMethodAndSig.minDailyDose },
          },
          medicationId: prescription.prescriptionToMedication.id,
        },
      }),
    {
      enabled:
        !!patientId ||
        !!prescription.id ||
        !!prescription.deliveryMethodAndSig.minDailyDose.value,
      retry: false,
      refetchOnMount: false,
      // onSuccess: (data) => {
      //   if (!prescription.deliveryMethodAndSig.currentDose) {
      //     setCurrentPrescriptionCheckValue(data.data);
      //   }
      // },
    }
  );

  const getPrescriptionDosageHistoryQuery = useQuery(
    QueryKeys.PrescriptionDosageHistory.listing({
      patientId,
      prescriptionId: prescription.id,
    }),
    () =>
      PatientPrescriptionService.getDoseHistory({
        patientId: patientId ?? '',
        prescriptionId: prescription.id,
      }),
    {
      enabled: !!patientId && !!prescription.id,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess(data) {
        if (data.data.length > 0) {
          if (!isSameDay(new GMTDate(data?.data[0].updatedAt), new Date())) {
            getPrescriptionCheckHistoryValueMutation.mutateAsync({
              dose: (
                data.data[0].dose.morning.value +
                data.data[0].dose.evening.value
              ).toFixed(2),
              id: data.data[0].id,
            });

            return;
          }

          getPrescriptionCheckHistoryValueMutation.mutateAsync({
            dose: (
              data.data[1].dose.morning.value + data.data[1].dose.evening.value
            ).toFixed(2),
            id: data.data[0].id,
          });
        }
      },
    }
  );

  const getPrescriptionCheckHistoryValueMutation = useMutation(
    ({ dose, id }: { dose: string; id: string }) => {
      // eslint-disable-next-line no-console
      console.log(id);

      return PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          deliveryMethodAndSig: {
            ...prescription.deliveryMethodAndSig,
            dose: {
              ...prescription.deliveryMethodAndSig.dose,
              value: dose,
            },
          },
          medicationId: prescription.prescriptionToMedication.id,
        },
      });
    },
    {
      onSuccess(data) {
        setHistoryDoseCheckValue(data?.data);
      },
    }
  );

  const getDoseRange = useCallback(() => {
    if (patientDetails.gender === 'F') {
      const hormone: {
        dose: string[];
        doseUnit: string;
      } = {
        ...femaleHormoneRxTypes?.[
          prescription.prescriptionToMedication.name
            .replaceAll(' ', '-')
            .toLocaleLowerCase()
        ],
      };

      if (
        HormoneNames.BI_EST.toLowerCase() ===
        prescription.prescriptionToMedication.name.toLocaleLowerCase()
      ) {
        hormone.dose = [
          ...hormone.dose.map((d) => d.toString()),
          '0.55',
          '0.60',
          '0.65',
        ];
      }

      if (
        HormoneNames.TESTO_PER_DHEA.toLowerCase() ===
          prescription.prescriptionToMedication.name.toLocaleLowerCase() &&
        !hormone.dose.includes('0.1')
      ) {
        hormone.dose = [
          '0.1',
          '0.15',
          ...hormone.dose.map((d) => d.toString()),
        ];
      } else if (hormone?.dose) {
        hormone.dose = hormone?.dose
          ? [...hormone.dose.map((d) => d.toString())]
          : [];
      }

      const doseRange = hormone?.dose.slice(
        hormone?.dose.indexOf(
          prescription.deliveryMethodAndSig?.minDailyDose?.value.toString()
        ),
        Number(
          hormone?.dose.indexOf(
            prescription.deliveryMethodAndSig?.dose?.value.toString() ?? 0
          )
        ) + 1
      );

      return doseRange;
    }

    if (patientDetails.gender === 'M') {
      const hormone: {
        dose: string[];
        doseUnit: string;
      } =
        maleHormoneRxTypes?.[
          prescription.prescriptionToMedication.name.toLocaleLowerCase()
        ];
      const doseRange = hormone?.dose.slice(
        hormone?.dose.indexOf(
          prescription.deliveryMethodAndSig?.minDailyDose?.value ?? 0
        ),
        Number(
          hormone?.dose.indexOf(
            prescription.deliveryMethodAndSig?.dose?.value ?? 0
          )
        ) + 1
      );

      return doseRange;
    }

    return [];
  }, [
    patientDetails.gender,
    prescription.deliveryMethodAndSig?.dose?.value,
    prescription.deliveryMethodAndSig?.minDailyDose?.value,
    prescription.prescriptionToMedication.name,
  ]);

  function splitDoseForBiest(dose: number) {
    if (dose % 2 === 0) {
      // If the dose is even, split it evenly
      return {
        morning: dose / 2,
        evening: dose / 2,
      };
    }
    // If the dose is odd, the larger portion goes to the evening
    const morning = Math.floor(dose * 10) / 10 / 2;
    const evening = dose - morning;

    return {
      morning,
      evening,
    };
  }

  function splitDose(type: string, doseInString: string) {
    const dose = Number(doseInString);
    const dosage = splitDoseForBiest(dose);
    switch (prescription?.prescriptionToMedication.name) {
      case HormoneNames.BI_EST:
        return type === 'Morning'
          ? Number(dosage.morning).toFixed(2)
          : Number(dosage.evening).toFixed(2);

      case HormoneNames.PROGESTERONE:
        return type === 'Morning' ? 0 : dose.toFixed(2);

      case HormoneNames.TESTOSTERONE_WOMEN:
      case HormoneNames.TESTO_PER_DHEA:
      case HormoneNames.DHEA:
        return type === 'Morning' ? dose : 0;

      default:
        return (dose / 2).toFixed(1);
    }
  }

  const calculatePercentageDifference = useCallback(
    (originalValue = '', currentValue = '') => {
      let difference = 0;
      let percentageDifference = 0;
      const isTestoDhea = !!(
        HormoneNames.TESTO_PER_DHEA.toLowerCase() ===
        prescription.prescriptionToMedication.name.toLocaleLowerCase()
      );
      if (isTestoDhea) {
        difference =
          Number(`${currentValue}`.split('/')?.[1]) -
          Number(`${originalValue}`.split('/')?.[1]);
        percentageDifference =
          (difference / Number(`${originalValue}`.split('/')?.[1])) * 100;
      } else {
        difference = Number(currentValue) - Number(originalValue);
        percentageDifference = (difference / Number(originalValue)) * 100;
      }

      if (Number.isNaN(percentageDifference)) {
        return {
          UI: <div />,
          difference: 0,
          currentValue,
          originalValue,
        };
      }

      if (
        percentageDifference === 0 ||
        (Number(originalValue) === 0 && Number(currentValue) === 0)
      ) {
        return {
          UI: (
            <div className="mt-1 flex justify-center gap-1 text-base text-background-contrastText">
              No change from prior dose
            </div>
          ),
          difference: 0,
          currentValue,
          originalValue,
        };
      }

      if (percentageDifference < 0) {
        return {
          UI: (
            <div className="mt-1 flex justify-center gap-1 text-base text-background-contrastText">
              <span className=" flex items-center pr-1 text-error-main">
                <ArrowDownIcon className="h-4 w-4 font-extrabold " />{' '}
                {percentageDifference.toFixed(0).replace('-', '')}%
              </span>
              decrease from {originalValue}{' '}
              {checkPrescriptionMaxDoseValueQuery.data?.data.unit}
            </div>
          ),
          difference: percentageDifference,
          currentValue,
          originalValue,
        };
      }

      return {
        UI: (
          <div className="mt-1 flex justify-center gap-1 text-base text-background-contrastText">
            <span className=" flex items-center pr-1 text-primary-main">
              <ArrowUpIcon className="h-4 w-4 font-extrabold " />{' '}
              {percentageDifference.toFixed(0)}%
            </span>
            increase from {originalValue}{' '}
            {checkPrescriptionMaxDoseValueQuery.data?.data.unit}
          </div>
        ),
        difference: percentageDifference,
        currentValue,
        originalValue,
      };
    },
    [
      checkPrescriptionMaxDoseValueQuery.data?.data.unit,
      prescription.prescriptionToMedication.name,
    ]
  );

  const getPercentageDifference = useMemo(() => {
    if (!getPrescriptionDosageHistoryQuery?.data?.data.length) {
      return calculatePercentageDifference(
        checkPrescriptionMinDoseValueQuery.data?.data.value ?? '',

        currentPrescriptionCheckValue?.value ??
          checkPrescriptionValueCurrentDoseQuery.data?.data.value
      );
    }
    // compare current and min dose
    if (
      isSameDay(
        new GMTDate(getPrescriptionDosageHistoryQuery?.data?.data[0].updatedAt),
        new Date()
      ) &&
      getPrescriptionDosageHistoryQuery?.data?.data.length === 1
    ) {
      return calculatePercentageDifference(
        checkPrescriptionMinDoseValueQuery.data?.data.value ?? '',

        currentPrescriptionCheckValue?.value ??
          checkPrescriptionValueCurrentDoseQuery.data?.data.value
      );
    }

    // compare last saved dose and current dose

    return calculatePercentageDifference(
      getPrescriptionCheckHistoryValueMutation.data?.data.value ??
        historyDoseCheckValue?.value,

      currentPrescriptionCheckValue?.value ??
        checkPrescriptionValueCurrentDoseQuery.data?.data.value
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getPrescriptionDosageHistoryQuery?.data?.data,
    checkPrescriptionValueCurrentDoseQuery.data?.data.value,
    getPrescriptionCheckHistoryValueMutation.data?.data,
    currentPrescriptionCheckValue?.value,
    checkPrescriptionMinDoseValueQuery.data?.data?.value,
  ]);

  const handleUpdateDose = (
    d: number,
    dose?: { morning: string; evening: string }
  ) => {
    setcurrentDose(d);
    getPrescriptionCheckMutation.mutate(getDoseRange()[d]);
    updateDoseMutation.mutate({
      morning: {
        value:
          dose?.morning ??
          splittedDose?.morning ??
          splitDose('Morning', getDoseRange()[d] ?? 0).toString(),
        unit: prescription?.titrationType
          ? prescription.deliveryMethodAndSig.dose.unit.replace('/day', '')
          : prescription.deliveryMethodAndSig.dose.unit,
      },
      evening: {
        value:
          dose?.evening ??
          splittedDose?.evening ??
          splitDose('Evening', getDoseRange()[d] ?? 0).toString(),
        unit: prescription?.titrationType
          ? prescription.deliveryMethodAndSig.dose.unit.replace('/day', '')
          : prescription.deliveryMethodAndSig.dose.unit,
      },
    });
    setshowDoseChangeConfModal(false);
  };

  // function getPatientDoseFormattedLable() {
  //   const doseUnit =
  //     checkPrescriptionMaxDoseValueQuery.data?.data.unit.split('/');

  //   return `(${doseUnit?.[0]})/${doseUnit?.[1]}` ?? '';
  // }

  function showAdjustDoseUI() {
    return prescription.category === 'current';
  }

  function showSlider() {
    if (!prescription) return false;

    const { deliveryMethodAndSig, category } = prescription;

    const hasMinDailyDose = !!deliveryMethodAndSig?.minDailyDose?.value;
    const doseDiffersFromMin =
      deliveryMethodAndSig?.dose?.value !==
      deliveryMethodAndSig?.minDailyDose?.value;
    const isCurrentCategory = category === 'current';

    // Show slider if all conditions are met
    return (
      hasMinDailyDose &&
      doseDiffersFromMin &&
      isCurrentCategory &&
      isTopicalHormone
    );
  }

  function getCurrentDoseValue(dose: string) {
    return isTopicalHormone ? Number(dose).toFixed(2) : dose;
  }

  const isTopicalHormone = useMemo(
    () =>
      !!prescription.standardRepresentation.includes('Topical') ||
      !!prescription.deliveryMethodAndSig.sig.includes('topically'),
    [prescription.standardRepresentation, prescription.deliveryMethodAndSig.sig]
  );

  const setTitrationSliderWithCurrentActiveDose = useCallback(
    (returnValueOnly?: boolean) => {
      const currentDoseIndex = getDoseRange()?.findIndex(
        (n) =>
          Number(n) ===
          Number(
            (
              Number(sameDayDosages?.morningDose?.value ?? 0) +
              Number(sameDayDosages?.eveningDose?.value ?? 0)
            ).toFixed(2)
          )
      );

      if (!returnValueOnly) {
        setcurrentDose(currentDoseIndex >= 0 ? currentDoseIndex : 0);
      }

      return currentDoseIndex >= 0 ? currentDoseIndex : 0;
    },
    [getDoseRange, sameDayDosages]
  );

  const setSliderWithCurrentActiveDose = useCallback(
    (returnValueOnly?: boolean) => {
      if (prescription?.titrationType) {
        return setTitrationSliderWithCurrentActiveDose(returnValueOnly);
      }

      const currentDoseIndex = getDoseRange()?.findIndex(
        (n) =>
          Number(n) ===
          Number(
            (
              Number(
                prescription.deliveryMethodAndSig.currentDose?.morning?.value
              ) +
              Number(
                prescription.deliveryMethodAndSig.currentDose?.evening?.value
              )
            ).toFixed(2)
          )
      );

      if (!returnValueOnly) {
        setcurrentDose(currentDoseIndex >= 0 ? currentDoseIndex : 0);
      }

      return currentDoseIndex >= 0 ? currentDoseIndex : 0;
    },
    [
      getDoseRange,
      prescription.deliveryMethodAndSig.currentDose,
      prescription?.titrationType,
      setTitrationSliderWithCurrentActiveDose,
    ]
  );

  useEffect(() => {
    if (prescription.deliveryMethodAndSig.currentDose) {
      setSliderWithCurrentActiveDose();

      return;
    }
    if (prescription?.titrationType) {
      setTitrationSliderWithCurrentActiveDose();
    }
  }, [
    prescription.deliveryMethodAndSig.currentDose,
    checkPrescriptionMaxDoseValueQuery.isLoading,
    getDoseRange,
    setSliderWithCurrentActiveDose,
    prescription?.titrationType,
    setTitrationSliderWithCurrentActiveDose,
  ]);

  useEffect(() => {
    if (allowUpdate) {
      getPrescriptionCheckMutation.mutate(getDoseRange()[Number(currentDose)]);
      setshowDoseChangeConfModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    if (radioButtonLoading) {
      setTimeout(() => {
        setRadioButtonLoading(false);
      }, 500);
    }
  }, [radioButtonLoading]);

  return (
    <SkeletonContent
      isLoading={checkPrescriptionMaxDoseValueQuery.isLoading}
      layout={adjustDoseSkeleton}>
      {() => (
        <div className="flex !w-full ">
          <div className="flex !w-full   flex-col gap-4 rounded bg-background-main p-8">
            <PrescriptionDetailsValue
              isTopicalHormone={isTopicalHormone}
              prescription={prescription}
              maxDoseCheckPrescriptionValue={
                checkPrescriptionMaxDoseValueQuery.data?.data
              }
              minDoseCheckPrescriptionValue={
                checkPrescriptionMinDoseValueQuery.data?.data
              }
            />
            <PrescriptionsDates prescription={prescription} />
            <div
              className={classNames(' non hidden w-full justify-end', {
                '!flex':
                  prescription.category !== 'current' && isTopicalHormone,
              })}>
              <Button
                onClick={() => setShowDosageHistory(true)}
                className={classNames(
                  ' !text-background-contrastText underline'
                )}
                variant="text">
                Dosage History
              </Button>
            </div>
            {}
            {showAdjustDoseUI() && (
              <>
                {' '}
                <div className="mt-6 flex flex-col gap-4">
                  <div className="flex flex-col gap-4">
                    <Typography variant="h4" color="textPrimary">
                      Current Dose
                      <InfoTooltip
                        fullWidth
                        className=""
                        label={
                          <span className="flex flex-col">
                            Providers can adjust dosages of currently active
                            prescriptions only. The dose adjustment feature
                            won&apos;t work -
                            <span>
                              If the minimum dose and maximum dose of the
                              prescription is exactly the same.
                            </span>{' '}
                            <span>
                              {' '}
                              If the prescription is not a topical prescription.
                            </span>
                          </span>
                        }
                        id="AdjustDosages"
                      />
                    </Typography>

                    {/* {prescription?.titrationType && (
                      <TitrationDetails
                        loading={checkPrescriptionMaxDoseValueQuery.isLoading}
                        titrationData={[
                          {
                            name: prescription.prescriptionToMedication.name,
                            medicationId:
                              prescription.prescriptionToMedication.id,
                            titration: prescription.titrations.map((d) => ({
                              day: d?.day,
                              morning: d?.morningDose?.value,
                              evening: d?.eveningDose?.value,
                            })),
                          },
                        ]}
                      />
                    )} */}

                    <div className={classNames('flex flex-col gap-1', {})}>
                      {(prescription.deliveryMethodAndSig.currentDose ||
                        prescription.deliveryMethodAndSig?.minDailyDose
                          ?.value ||
                        prescription.deliveryMethodAndSig.dose?.value) && (
                        // eslint-disable-next-line react/jsx-indent
                        <Typography variant="subtitle1" color="textMuted">
                          {`This patient is currently taking ${getCurrentDoseValue(
                            getDoseRange()?.[currentDose as number] ??
                              prescription?.deliveryMethodAndSig?.dose?.value
                          )} ${
                            isTopicalHormone
                              ? prescription.deliveryMethodAndSig.dose.unit
                              : formatDeliveryUnit(
                                  Number(
                                    getDoseRange()?.[currentDose as number]
                                  ),
                                  prescription.deliveryMethodAndSig.dose.unit
                                )
                          }.`}
                        </Typography>
                      )}
                      {showSlider() && !prescription?.titrationType && (
                        <Typography variant="subtitle1" color="textMuted">
                          {`When you created this prescription the min and max dose was
                    set to ${
                      isTopicalHormone
                        ? Number(
                            prescription.deliveryMethodAndSig?.minDailyDose
                              ?.value
                          ).toFixed(2)
                        : prescription.deliveryMethodAndSig?.minDailyDose?.value
                    } ${prescription.deliveryMethodAndSig.dose.unit} & ${
                            isTopicalHormone
                              ? Number(
                                  prescription.deliveryMethodAndSig?.dose.value
                                ).toFixed(2)
                              : prescription.deliveryMethodAndSig?.dose.value
                          } ${
                            prescription.deliveryMethodAndSig.dose.unit
                          } respectively. Now you can adjust the
                    prescription between the same range.`}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div className={classNames('mt-2 flex items-center', {})}>
                    <div
                      className={classNames('  grid grow grid-cols-10 px-2')}>
                      {showSlider() ? (
                        <PrescriptionSlider
                          label=""
                          value={currentDose as number}
                          onChange={(v) => {
                            setAllowUpdate(true);
                            setcurrentDose(v);
                          }}
                          getTooltip={(value) =>
                            `${
                              isTopicalHormone
                                ? Number(getDoseRange()[value]).toFixed(2)
                                : getDoseRange()[value]
                            } ${
                              isTopicalHormone
                                ? prescription.deliveryMethodAndSig.dose.unit
                                : formatDeliveryUnit(
                                    Number(getDoseRange()[value]),
                                    prescription.deliveryMethodAndSig.dose.unit
                                  )
                            }`
                          }
                          options={getDoseRange()}
                        />
                      ) : (
                        <div
                          className={classNames('visible flex gap-20', {
                            '!hidden':
                              !isTopicalHormone ||
                              !!prescription?.titrationType ||
                              !prescription?.deliveryMethodAndSig
                                ?.minDailyDose ||
                              !prescription?.deliveryMethodAndSig?.dose?.value,
                          })}>
                          <div>
                            <Typography
                              className=" whitespace-nowrap"
                              variant="h5"
                              align="left">
                              Morning Dose
                            </Typography>
                            <div className="mt-1 min-w-[10rem] rounded bg-background-light !py-2 !px-4 text-center  text-sm font-semibold text-background-contrastText">
                              {splitDose(
                                'Morning',
                                getDoseRange()?.[currentDose ?? 0]
                              )}
                            </div>
                          </div>{' '}
                          <div>
                            <Typography
                              className=" whitespace-nowrap"
                              variant="h5"
                              align="left">
                              Evening Dose
                            </Typography>{' '}
                            <div className="mt-1 min-w-[10rem] rounded bg-background-light !py-2 !px-4 text-center  text-sm font-semibold text-background-contrastText">
                              {splitDose(
                                'Evening',
                                getDoseRange()?.[currentDose ?? 0]
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className={classNames('w-60', {
                        hidden:
                          !isTopicalHormone ||
                          !prescription?.deliveryMethodAndSig?.minDailyDose ||
                          !prescription?.deliveryMethodAndSig?.dose?.value,
                      })}>
                      <Typography
                        className=" whitespace-nowrap"
                        variant="h5"
                        align="left">
                        Daily{' '}
                        {checkPrescriptionMaxDoseValueQuery?.data?.data.unit}
                      </Typography>
                      {getPrescriptionCheckMutation.isLoading ? (
                        <div className=" mt-1 h-[2rem] min-w-[10rem] animate-pulse rounded bg-background-light" />
                      ) : (
                        <div className="mt-1 min-w-[10rem] rounded bg-[#00D9AF] !py-2 !px-4 text-center  text-sm font-semibold text-background-light">
                          {currentPrescriptionCheckValue?.value
                            ? `${currentPrescriptionCheckValue?.value} ${currentPrescriptionCheckValue?.unit}`
                            : `${checkPrescriptionValueCurrentDoseQuery.data?.data.value} ${checkPrescriptionValueCurrentDoseQuery.data?.data.unit}`}
                        </div>
                      )}
                      {!prescription?.titrationType && (
                        <div className="mt-1 flex min-h-[1.5rem] justify-center whitespace-nowrap">
                          {checkPrescriptionValueCurrentDoseQuery.isLoading ||
                          getPrescriptionCheckHistoryValueMutation.isLoading ||
                          getPrescriptionCheckMutation.isLoading ? (
                            <div className=" mt-2 h-[1rem] w-[80%] animate-pulse rounded bg-background-light" />
                          ) : (
                            getPercentageDifference.UI
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    'mt-4 flex items-center justify-between '
                  )}>
                  <div
                    className={classNames('flex hidden gap-20', {
                      '!flex': showSlider(),
                    })}>
                    {/* <div>
                      <Typography
                        className=" whitespace-nowrap"
                        variant="h5"
                        align="left">
                        Morning Dose
                      </Typography>
                      <div className="mt-1 min-w-[10rem] rounded bg-background-light !py-2 !px-4 text-center  text-sm font-semibold text-background-contrastText">
                        {splitDose('Morning', getDoseRange()[currentDose ?? 0])}
                      </div>
                    </div>{' '}
                    <div>
                      <Typography
                        className=" whitespace-nowrap"
                        variant="h5"
                        align="left">
                        Evening Dose
                      </Typography>{' '}
                      <div className="mt-1 min-w-[10rem] rounded bg-background-light !py-2 !px-4 text-center  text-sm font-semibold text-background-contrastText">
                        {splitDose('Evening', getDoseRange()[currentDose ?? 0])}
                      </div>
                    </div> */}
                    <DoseSplitter
                      className={classNames({
                        '!hidden': !!prescription?.titrationType,
                      })}
                      defaultValue={{
                        morning:
                          prescription?.deliveryMethodAndSig?.currentDose
                            ?.morning?.value,
                        evening:
                          prescription?.deliveryMethodAndSig?.currentDose
                            ?.evening?.value,
                      }}
                      value={getDoseRange()?.[currentDose as number]}
                      onChange={(d, type) => {
                        setRadioButtonLoading(true);
                        setSplittedDose(d);

                        if (!!prevSplitType && prevSplitType !== type) {
                          handleUpdateDose(debouncedValue as number, {
                            morning: d.morning,
                            evening: d.evening,
                          });
                        }
                        setPrevSplitType(type);
                      }}
                      hormoneName={prescription?.prescriptionToMedication.name}
                      loading={
                        updateDoseMutation.isLoading && radioButtonLoading
                      }
                    />
                  </div>
                  <Button
                    onClick={() => setShowDosageHistory(true)}
                    className={classNames(
                      'visible !text-background-contrastText underline',
                      {
                        '!invisible':
                          prescription.deliveryMethodAndSig.dose.value ===
                            prescription.deliveryMethodAndSig?.minDailyDose
                              ?.value || !isTopicalHormone,
                      }
                    )}
                    variant="text">
                    Dosage History
                  </Button>
                </div>
              </>
            )}
            {prescription?.titrationType &&
              prescription?.category === 'current' && (
                <TitrationDetails
                  className="pt-0"
                  loading={checkPrescriptionMaxDoseValueQuery.isLoading}
                  titrationData={[
                    {
                      name: prescription.prescriptionToMedication.name,
                      medicationId: prescription.prescriptionToMedication.id,
                      titration: prescription.titrations.map((d) => ({
                        day: d?.day,
                        morning: d?.morningDose?.value,
                        evening: d?.eveningDose?.value,
                      })),
                    },
                  ]}
                />
              )}
          </div>

          <PrescriptionDosageHistoryModal
            title={`${prescription.prescriptionToMedication.name} Dosage Adjustment History`}
            onClose={() => {
              setShowDosageHistory(false);
            }}
            prescriptionId={prescription.id}
            isOpen={showDosageHistory}
            prescription={{
              ...prescription,
              currentDoseIndex: currentDose ?? 0,
            }}
          />
          {showDoseChangeConfModal && (
            <ChangeDoseConfirmationModal
              prescriptionId={prescription.id}
              titrationType={prescription?.titrationType}
              spittedDose={splittedDose}
              prescriptionName={prescription.standardRepresentation}
              open={showDoseChangeConfModal}
              prescriptionDifference={getPercentageDifference}
              unit={checkPrescriptionMaxDoseValueQuery.data?.data.unit ?? ''}
              loading={
                (getPrescriptionCheckMutation.isLoading ||
                  getPrescriptionCheckHistoryValueMutation.isLoading) &&
                allowUpdate
              }
              onCancel={() => {
                setAllowUpdate(false);
                getPrescriptionCheckMutation.mutate(
                  getDoseRange()[setSliderWithCurrentActiveDose()]
                );

                setSliderWithCurrentActiveDose();
                setshowDoseChangeConfModal(false);
              }}
              onConfirm={() => {
                handleUpdateDose(debouncedValue as number);
              }}
            />
          )}
        </div>
      )}
    </SkeletonContent>
  );
}
