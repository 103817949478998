import React, { useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';

import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import { PatientPrescriptionService } from 'services/prescription';
import { ThemeName } from 'theme/types';

type ChangeDoseConfirmationModalProps = {
  loading: boolean;
  open: boolean;
  prescriptionName: string;
  prescriptionDifference: {
    UI: JSX.Element | string;
    difference: number;
    currentValue: string | number;
    originalValue: string | number;
  };
  unit: string;
  onCancel: () => void;
  onConfirm: () => void;
  prescriptionId: string;
  spittedDose?: {
    morning: string;
    evening: string;
  };
  titrationType?: string;
};

export function ChangeDoseConfirmationModal({
  open,
  prescriptionName,
  prescriptionDifference,
  unit,
  loading,
  onCancel,
  onConfirm,
  prescriptionId,
  spittedDose,
  titrationType,
}: ChangeDoseConfirmationModalProps) {
  const getTitrationStatusCheckMutation = useMutation(() =>
    PatientPrescriptionService.checkTitrationStatus({
      prescriptionId,
      currentDose: {
        evening: { value: spittedDose?.evening ?? '', unit: '' },
        morning: { value: spittedDose?.morning ?? '', unit: '' },
      },
    })
  );

  function getConfirmationText() {
    if (
      titrationType &&
      !!getTitrationStatusCheckMutation?.data?.data?.titrationStopped
    ) {
      return (
        <Typography variant="subtitle1" className="flex  !items-start">
          <span>
            The titration protocol will be stopped as the dose has been changed
            to
            <span className="mx-1 font-bold text-primary-main">
              {prescriptionDifference?.currentValue} {unit}.
            </span>{' '}
            Please note that once the titration is stopped, it cannot be resumed
            after today.
          </span>
        </Typography>
      );
    }

    if (
      titrationType &&
      !getTitrationStatusCheckMutation?.data?.data?.titrationStopped
    ) {
      return (
        <Typography variant="subtitle1" className="flex  !items-start">
          <span>
            The titration protocol will be adjusted because the dose has been
            changed to
            <span className="mx-1 font-bold text-primary-main">
              {prescriptionDifference?.currentValue} {unit}.
            </span>{' '}
          </span>
        </Typography>
      );
    }

    return (
      <Typography variant="subtitle1" className="flex h-20 !items-start">
        {prescriptionDifference?.difference === 0 ? (
          <span>
            You have reset the dose of{' '}
            <span className="font-bold text-primary-main">
              {prescriptionName}.
            </span>{' '}
          </span>
        ) : (
          <span>
            You have changed the current dose of{' '}
            <span className="font-bold text-primary-main">
              {prescriptionName}
            </span>{' '}
            from{' '}
            <span className="font-bold text-primary-main">
              {prescriptionDifference?.originalValue} {unit}
            </span>{' '}
            to{' '}
            <span className="font-bold text-primary-main">
              {prescriptionDifference?.currentValue} {unit}
            </span>
            . This is a{' '}
            <span className="font-bold text-primary-main">
              {prescriptionDifference?.difference
                .toFixed(1)
                .toString()
                .replace('-', '')}
              %{' '}
              {prescriptionDifference?.difference < 0 ? 'decrease' : 'increase'}
            </span>{' '}
            compared to the existing dose.
          </span>
        )}
      </Typography>
    );
  }

  useEffect(() => {
    if (spittedDose?.evening && spittedDose?.morning) {
      getTitrationStatusCheckMutation.mutate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spittedDose]);

  return (
    <Modal
      className=" w-1/2 max-w-[700px] px-4"
      open={open}
      closeModal={() => {
        //
      }}>
      <SkeletonContent
        theme={ThemeName.Light}
        layout={[
          {
            className: 'flex flex-col w-full gap-6 ',
            children: [
              {
                className: 'w-full h-20',
              },
              {
                className: 'w-60 h-4 mt-2',
              },
              {
                className: ' flex gap-8 self-end',
                children: [
                  {
                    className: 'w-24 h-8',
                  },
                  {
                    className: 'w-24 h-8',
                  },
                ],
              },
            ],
          },
        ]}
        isLoading={loading || getTitrationStatusCheckMutation.isLoading}>
        {() => (
          <div className="flex flex-col gap-4">
            <Typography variant="h3">
              Do you wish to apply these changes?
            </Typography>
            {getConfirmationText()}
            <div className="mt-4 flex gap-8 self-end ">
              <Button onClick={onCancel} color="secondary">
                Cancel
              </Button>
              <Button onClick={onConfirm}>Confirm</Button>
            </div>
          </div>
        )}
      </SkeletonContent>
    </Modal>
  );
}
