import { useEffect, useState } from 'react';

import { ErrorBoundary } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { Navigate, NavLink, Outlet, useLocation } from 'react-router-dom';

import { ConfirmationModal } from 'components/common/ConfirmationModal/ConfirmationModal';
import { Typography } from 'components/common/Typography/Typography';
import { RequireAuth } from 'components/functional/RequireAuth/RequireAuth';
import { DEALicenseExpirationReminderTile } from 'components/general/DEALicenseExpirationReminderTile/DEALicenseExpirationReminderTile';
import { ErrorFallbackComponent } from 'components/general/ErrorFallbackComponent/ErrorFallbackComponent';
import { Header } from 'components/general/Header/Header';
import { SwitchLocationButtonWithModal } from 'components/patient/SwitchLocationButtonWithModal/SwitchLocationButtonWithModal';
import { QueryKeys } from 'constants/query-keys';
import { SocketProvider } from 'context/SocketProvider/SocketProvider';
import { useProfile } from 'hooks/useProfile';
import { removeAccessAndRefreshTokens } from 'lib/general/token';
import { UserTypes } from 'types/common';
import { getLogoutUrl } from 'utils/common';

export function AppLayout() {
  const [showNoPracticeModal, setShowNoPracticeModal] = useState(false);
  const location = useLocation();
  const { profile } = useProfile();
  const queryClient = useQueryClient();

  const tabs = [
    // { title: 'Dashboard', link: '/app/dashboard' },
    { title: 'Patients', link: '/app/patients' },
  ];

  const handleLogout = () => {
    removeAccessAndRefreshTokens();
    queryClient.refetchQueries([QueryKeys.UserProfile]);
    window.location.replace(
      getLogoutUrl(profile?.role.name || UserTypes.Provider)
    );
  };

  useEffect(() => {
    if (profile?.id && !profile?.practice?.id) {
      setShowNoPracticeModal(true);
    }
  }, [profile?.practice?.id, profile?.id]);

  if (location?.pathname === '/app') {
    return <Navigate to="./patients" />;
  }

  const isChatScreenOpen = location.pathname === '/app/chat';

  return (
    <ErrorBoundary fallback={ErrorFallbackComponent}>
      <SocketProvider>
        <RequireAuth>
          <div className=" min-h-screen bg-background-dark">
            <div className="container bg-background-dark">
              <div className="py-4">
                <Header />
                {isChatScreenOpen ? null : (
                  <>
                    <DEALicenseExpirationReminderTile />
                    <SwitchLocationButtonWithModal />
                  </>
                )}
                <div
                  className={`mt-3 ml-1 mb-3 flex w-full border-b-2 border-background-light text-background-contrastText ${
                    isChatScreenOpen ? 'hidden' : ''
                  }`}>
                  {tabs.map((tab) => (
                    <NavLink
                      to={tab.link}
                      className={({ isActive }) =>
                        `mr-5 ml-4 inline-block px-4 py-2 text-lg font-bold  ${
                          isActive ? 'text-primary-main ' : 'text-zinc-400 '
                        }`
                      }>
                      <div key={tab.title} className="">
                        <Typography style={{ color: 'inherit' }} variant="h3">
                          {tab.title}
                        </Typography>
                      </div>
                    </NavLink>
                  ))}
                </div>
                <Outlet />
              </div>
            </div>
            <ConfirmationModal
              infoButtonCancelText="Logout"
              title="You are not registered with any practice. Please contact Brite at “support@brite.live” for further assistance."
              isModalOpen={showNoPracticeModal}
              closeModal={() => {
                //
              }}
              onCancel={handleLogout}
              buttonColor={{
                cancelBtn: 'error',
              }}
            />
          </div>
        </RequireAuth>
      </SocketProvider>
    </ErrorBoundary>
  );
}
