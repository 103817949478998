import React from 'react';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';
import { PasswordChecker } from 'components/common/PasswordChecker/PasswordChecker';
import { updatePasswordSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';
import { ThemeName } from 'theme/types';

type UpdatePasswordProps = {
  modal: boolean;
  closeModal: (state: boolean) => void;
};

type PasswordFormType = {
  newPassword: string;
  oldPassword: string;
};
export function UpdatePassword({ modal, closeModal }: UpdatePasswordProps) {
  const updatePasswordMutation = useMutationWithToast(
    UserService.updateUserPassword
  );

  const { handleSubmit, control, reset, watch } =
    useFormWithErrors<PasswordFormType>({
      mutation: updatePasswordMutation,
      schema: updatePasswordSchema,
    });
  const handleCloseModal = () => {
    closeModal(false);
    reset();
  };

  const onSubmit = handleSubmit((data) => {
    updatePasswordMutation.mutate(
      { oldPassword: data.oldPassword, password: data.newPassword },
      {
        onSuccess: () => {
          handleCloseModal();
        },
      }
    );
  });

  const password = watch('newPassword', '');
  const validations = {
    length: password?.length >= 8,
    lowercase: /[a-z]/.test(password ?? ''),
    uppercase: /[A-Z]/.test(password),
    number: /\d/.test(password),
    specialChar: /[-@#$%^&*()_]/.test(password),
  };

  return (
    <div>
      <Modal
        title="Change Password"
        open={modal}
        className="w-2/5"
        closeModal={handleCloseModal}>
        <div className="mt-4">
          <TextInput
            className="pl-2 "
            type="password"
            name="oldPassword"
            control={control}
            fullWidth
            placeholder="Current Password"
          />
          <TextInput
            className="pl-2"
            type="password"
            name="newPassword"
            control={control}
            fullWidth
            placeholder="New Password"
          />
          <TextInput
            className="pl-2"
            type="password"
            name="confirmPassword"
            control={control}
            fullWidth
            placeholder="Confirm Password"
          />
          <PasswordChecker theme={ThemeName.Light} validations={validations} />

          <div>
            <div className=" mt-6 flex justify-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => handleCloseModal()}>
                Close
              </Button>
              <span className="mx-2" />
              <Button
                type="submit"
                loading={updatePasswordMutation.isLoading}
                onClick={onSubmit}>
                Update Password
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
