import React, { useState } from 'react';

import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useOutletContext } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';
import { Avatar } from 'components/general/Avatar/Avatar';
import { GMTDate } from 'lib/general/GMT-date';
import { Note as NoteType } from 'services/patient/types';
import { PatientDetailsContextType } from 'types/patient';

import { NotesPDF } from './NotesPDF';

interface NoteProps {
  note: NoteType;
  className?: string;
}
export function Note({ note, className }: NoteProps) {
  const { firstName, lastName, gender, phone, email, dob } =
    useOutletContext<PatientDetailsContextType>();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`flex flex-col ${className} `}>
      <Typography
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: isExpanded ? 'unset' : 3,
        }}
        className="mr-4 !overflow-hidden !whitespace-normal break-words"
        variant="subtitle2">
        {note?.note}
      </Typography>
      {note?.note.length > 226 && (
        <div>
          <Button
            className="!px-0"
            onClick={() => {
              handleToggle();
            }}
            variant="text">
            {isExpanded ? 'Read Less' : 'Read More'}
          </Button>
        </div>
      )}
      <div className="mt-2 flex  items-center self-end">
        <Avatar
          imgUrl={note?.createdByUser?.pictureUrl || ''}
          userName={note?.createdByUser?.name || ''}
          sizeClass="w-10 h-10 mr-3"
        />
        <div>
          <Typography variant="subtitle2" color="textMuted">
            {note?.createdByUser?.name}
          </Typography>
          <Typography variant="subtitle2" color="textMuted">
            {new GMTDate(note?.createdAt).getReadableDateInGMT()}
          </Typography>
        </div>
        <PDFDownloadLink
          className="ml-4"
          document={
            <NotesPDF
              patientDetails={{
                firstName,
                lastName,
                gender,
                email,
                phone,
                dob,
              }}
              notes={[note]}
            />
          }
          fileName={`${firstName}_${lastName}_Note`}>
          {() => (
            <Button variant="outlined" className="!py-1">
              <ArrowDownTrayIcon className="h-5 w-5 text-primary-light" />
            </Button>
          )}
        </PDFDownloadLink>
      </div>
    </div>
  );
}
