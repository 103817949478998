import React, { useState } from 'react';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Modal } from 'components/common/Modal/Modal';

type AddNoteModalProps = {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  onSubmit: (note: string, clearNote: () => void) => void;
};

export function AddNoteModal({
  open,
  onClose,
  loading,
  onSubmit,
}: AddNoteModalProps) {
  const [note, setNote] = useState('');

  function clearNote() {
    setNote('');
  }

  return (
    <Modal
      title="Add Note"
      open={open}
      className="w-2/5"
      closeModal={() => {
        //
      }}>
      <div className="mt-4">
        <TextInput
          value={note}
          rows={4}
          multiline
          fullWidth
          type="text"
          placeholder="Please enter note"
          onChange={(v) => setNote(v)}
        />

        <div>
          <div className="mt-2  flex justify-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setNote('');
                onClose();
              }}>
              Close
            </Button>
            <span className="mx-2" />
            <Button
              disabled={!note.trim()}
              type="submit"
              loading={loading}
              onClick={() => onSubmit(note.trim(), clearNote)}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
