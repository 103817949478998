import * as yup from 'yup';

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
});

const phone = {
  phone: yup
    .string()
    .required('Please enter phone number')
    .min(9, 'Please enter valid phone')
    .max(14, 'Please enter valid phone')
    .typeError('Phone should be number'),
};

export const nameSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(32)
    .required('Please enter first name')
    .min(3, 'Please enter valid first name'),
  middleName: yup.string().max(32).nullable(true),
  lastName: yup
    .string()
    .max(32)
    .required('Please enter last name')
    .min(3, 'Please enter valid last name'),
});

export const phoneSchema = yup.object().shape({ ...phone });

export const loginCredSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
  password: yup.string().min(8).max(32).required('Please enter password'),
});

export const invitePatientSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
  name: yup
    .string()
    .max(32)
    .required('Please enter name')
    .min(3, 'Please enter valid name'),

  phone: yup.string().max(32),
});

export const resetPasswordSchema = yup.object({
  password: yup.string().required('Please enter your new password.'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});

export const updatePasswordSchema = yup.object({
  oldPassword: yup.string().required('Please enter your old password.'),
  newPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(
      /[-@#$%^&*()_]/,
      'Password must contain at least one special character like -, @, #, $, %, ^, &, *, (, ), _'
    ),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('newPassword')], 'Your passwords do not match.'),
});

const address = {
  addressLine1: yup.string().required('Please enter address.'),
  addressLine2: yup.string().nullable(true),
  city: yup.string().required('Please enter city.'),
  country: yup.string().required('Please enter country.'),
  stateId: yup.string().required('Please select state.'),
  zipCode: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')
    .required('Please enter zip code.')
    .typeError('Zip Code should be number'),
};

export const addressSchema = yup.object({
  ...address,
});

export const providerOnboardSchema = yup.object().shape({
  firstName: yup.string().max(32).required('Please enter first name'),
  lastName: yup.string().max(32).required('Please enter last name'),
  ...phone,

  password: yup.string().min(8).max(32).required('Please enter password'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),

  dea: yup
    .string()
    .trim()
    .matches(/[a-zA-Z]{2}\d{7}$/, 'Please enter valid DEA number.')
    .required(),
  licenseNumber: yup.string().required('Please enter license number'),

  npi: yup
    .string()
    .required('Please enter NPI number')
    .min(10, 'Please enter 10 digit NPI number')
    .max(10, 'Please enter 10 digit NPI number'),

  specialities: yup
    .array()
    .min(1, 'Please select specialty ')
    .required('Please select specialty '),
  officeManagerName: yup.string().max(32).required('Please enter manager name'),
  officeManagerEmail: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter manager email'),
});

export const diagnosisFormSchema = (hormone: boolean, thyroid: boolean) =>
  yup.object().shape({
    ...(hormone && {
      hormoneCode: yup
        .string()
        .max(32)
        .required('Please select diagnostic reason'),
    }),
    ...(thyroid && {
      thyroidCode: yup
        .string()
        .max(32)
        .required('Please select diagnostic reason'),
    }),
  });

export const updateManagerDetailsSchema = (type?: string) => {
  switch (type) {
    case 'Name':
      return yup.object().shape({
        name: yup
          .string()
          .max(32)
          .required('Please enter name')
          .min(3, 'Please enter valid name'),
      });
    case 'Email':
      return yup.object().shape({
        email: yup
          .string()
          .email('Please enter valid email')
          .required('Please enter email'),
      });
    case 'Gender':
      return yup.object().shape({
        gender: yup.string().max(32).required('Please select gender'),
      });

    case 'DOB':
      return yup.object().shape({
        dob: yup.string().required('Please select DOB'),
      });

    case 'CalendarLink':
      return yup.object().shape({
        link: yup.string().url('Please enter a valid link.').nullable(true),
      });
    default:
      return yup.object().shape({
        error: yup.string().max(32).required('error').min(3, 'error'),
      });
  }
};
export const allergiesMedicationSchema = (type: string) =>
  type === 'Allergies'
    ? yup.object().shape({
        patientKnownAllergies: yup
          .string()
          .required('Please enter known allergies'),
      })
    : yup.object().shape({
        patientOtherMedications: yup
          .string()
          .required('Please enter other medications'),
      });

export const deaAndLicenseSchema = (type: string) =>
  yup.object({
    ...(type === 'DEA' && {
      dea: yup
        .string()
        .trim()
        .matches(/[a-zA-Z]{2}\d{7}$/, 'Please enter valid DEA number.')
        .required('Please enter DEA number'),
    }),
    ...(type === 'License' && {
      license: yup.string().max(32).required('Please enter license number'),
    }),
    stateId: yup.string().max(32).required('Please select state'),
  });

export const newCropDataSchema = yup.object({
  practice: yup.object({
    id: yup.string().required('Practice ID not found.'),
    name: yup
      .string()
      .required(
        'Practice name not found or is has spacial characters in it which are not allowed.'
      ),
    location: yup.object({
      id: yup.string().required('Practice location ID not found.'),
      name: yup.string().required('Practice location name not found.'),
      phoneNumber: yup
        .string()
        .required(
          'The phone number for the practice location is not provided.'
        ),
      faxNumber: yup
        .string()
        .required('The fax number for the practice location is not provided.'),
      address: yup.object({
        addressLine1: yup
          .string()
          .required('The address for the practice location is not provided.'),
        city: yup
          .string()
          .required('The city for the practice location is not provided.'),
        country: yup
          .string()
          .required('The country for the practice location is not provided.'),
        state: yup.object({
          abbreviation: yup
            .string()
            .required('The state for the practice location is not provided.'),
        }),
        zipCode: yup
          .string()
          .required('The zip code for the practice location is not provided.'),
      }),
    }),
  }),
  provider: yup.object({
    id: yup.string().required('Your profile ID is not found.'),
    firstName: yup.string().required('Your first name is not found.'),
    lastName: yup.string().required('Your last name is not found.'),
    suffix: yup.string().required('Your provider credentials are missing.'),
    npi: yup.string().required("You don't have an NPI number."),
    phone: yup.string().required('You have not provided your phone number.'),
    baseDea: yup.string().required('Missing base DEA number'),
  }),

  patient: yup.object().shape(
    {
      id: yup.string().required("Patient's ID not found."),
      firstName: yup.string().required("Patient's first name not found."),
      lastName: yup.string().required("Patient's last name not found."),
      address: yup.object().shape({
        addressLine1: yup.string().required("Patient's address not found."),
        city: yup.string().required("Patient's city not found."),
        country: yup.string().required("Patient's country not found."),
        state: yup.object().shape({
          abbreviation: yup.string().required("Patient's state not found."),
        }),
        zipCode: yup.string().required("Patient's zip code not found."),
      }),
      email: yup.string().required("Patient's email not found."),
      phoneNumber: yup.string().required("Patient's phone number is missing."),
      DOB: yup.string().required("Patient's Date of birth is missing."),
      gender: yup.string().required("Patient's gender is missing."),
      diagnosis: yup.object().shape(
        {
          hormone: yup.object().when('thyroid', {
            is: (code: unknown) => !code,
            then: yup
              .object()
              .required("Patient's hormone diagnosis code is missing."),
          }),
          thyroid: yup.object().when('hormone', {
            is: (code: unknown) => !code,
            then: yup
              .object()
              .required("Patient's thyroid diagnosis code is missing."),
          }),
        },
        [['hormone', 'thyroid']]
      ),

      SSNNumber: yup.string().when('drivingLicense', {
        is: (number: string) => !number,
        then: yup.string().required("Patient's SSN number is missing."),
      }),
      drivingLicense: yup.object().when('SSNNumber', {
        is: (number: string) => !number,
        then: yup.object({
          id: yup
            .string()
            .required("Patient's Driver's License information not found."),
          licenseNumber: yup
            .string()
            .required("Patient's driver's license number is missing."),
          expDate: yup
            .string()
            .required("Patient's Driver's License Exp date not found."),
          state: yup.object({
            name: yup
              .string()
              .required("Patient's Driver's License State not found."),
          }),
        }),
      }),
    },
    [['SSNNumber', 'drivingLicense']]
  ),
});

export const deaSchema = () =>
  yup.object().shape({
    dea: yup
      .string()
      .trim()
      .matches(/[a-zA-Z]{2}\d{7}$/, 'Please enter valid DEA number.')
      .required('Please enter DEA number'),

    deaExpiry: yup
      .date()
      .required('Please select an expiration Date')
      .transform((value, originalValue) => {
        const parsedDate = Date.parse(originalValue);

        // eslint-disable-next-line no-restricted-globals
        return isNaN(parsedDate) ? new Date() : new Date(parsedDate);
      })
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)),
        'The expiration date must be in the future'
      ),
  });

export const addDeaAndLicenseSchema = () =>
  yup.object().shape({
    license: yup.string().max(32).required('Please enter license number'),

    stateId: yup.string().max(32).required('Please select state'),
    licenseExpiry: yup
      .date()
      .required('Please select an expiration Date')
      .transform((value, originalValue) => {
        const parsedDate = Date.parse(originalValue);

        // eslint-disable-next-line no-restricted-globals
        return isNaN(parsedDate) ? new Date() : new Date(parsedDate);
      })
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)),
        'The expiration date must be in the future'
      ),
  });

export const supervisingDoctorSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(32)
    .required('Please enter first name')
    .min(3, 'Please enter valid name'),
  lastName: yup
    .string()
    .max(32)
    .required('Please enter last name')
    .min(3, 'Please enter valid name'),
  suffix: yup.string().max(32).required('Please select title'),
  supervisingDea: yup
    .string()
    .trim()
    .matches(/[a-zA-Z]{2}\d{7}$/, 'Please enter valid DEA number.')
    .required('Please enter DEA number'),
  licenseNumber: yup.string().required('Please enter license number'),
  licenseState: yup.string().max(32).required('Please select license state'),
  middleName: yup.string().max(32).nullable(),
  npi: yup
    .string()
    .matches(/^\d{10}$/, 'NPI should be exactly 10 digits')
    .required('Please enter NPI number'),
});

export const patientDetailsSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(32)
    .required('Please enter first name')
    .min(3, 'Please enter valid first name'),
  middleName: yup.string().max(32).nullable(true),
  lastName: yup
    .string()
    .max(32)
    .required('Please enter last name')
    .min(3, 'Please enter valid last name'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
  phone: yup.string().required('Please enter phone number'),
  dob: yup.string().required('Please select DOB'),
});
