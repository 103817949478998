import React, { useEffect, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { Table } from 'components/common/Table/Table';
import { QueryKeys } from 'constants/query-keys';
import { GMTDate } from 'lib/general/GMT-date';
import { PatientPrescriptionService } from 'services/prescription';
import { ThemeName } from 'theme/types';
import {
  DosageHistory,
  PatientPrescription,
  PrescriptionCheckType,
} from 'types/patient/prescription';

type PrescriptionDosageHistoryModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  prescriptionId: string;
  prescription: PatientPrescription & { currentDoseIndex: number };
};

export function PrescriptionDosageHistoryModal({
  isOpen,
  onClose,
  prescriptionId,
  title,
  prescription,
}: PrescriptionDosageHistoryModalProps) {
  const queryClient = useQueryClient();

  const { id: patientId } = useParams();
  const getPrescriptionDosageHistoryQuery = useQuery(
    QueryKeys.PrescriptionDosageHistory.listing({
      patientId,
      prescriptionId,
      doseIndex: prescription.currentDoseIndex,
    }),
    () =>
      PatientPrescriptionService.getDoseHistory({
        patientId: patientId ?? '',
        prescriptionId,
      }),
    {
      enabled: !!patientId && !!prescriptionId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const [prescriptionCheckValue, setPrescriptionCheckValue] = useState<{
    [x: string]: PrescriptionCheckType;
  }>({});

  const getPrescriptionCheckMutation = useMutation(
    ({ dose, id }: { dose: string; id: string }) => {
      // eslint-disable-next-line no-console
      console.log(id);

      return PatientPrescriptionService.checkPrescription({
        patientId: patientId ?? '',
        medication: {
          ...prescription,
          deliveryMethodAndSig: {
            ...prescription.deliveryMethodAndSig,
            dose: {
              ...prescription.deliveryMethodAndSig.dose,
              value: dose,
            },
          },
          medicationId: prescription.prescriptionToMedication.id,
        },
      });
    },
    {
      onSuccess(data, variable) {
        setPrescriptionCheckValue((pre) => ({
          ...pre,
          [variable.id]: data?.data,
        }));
      },
    }
  );

  const getCheckValue = () => {
    getPrescriptionDosageHistoryQuery.data?.data.forEach((p) => {
      getPrescriptionCheckMutation.mutateAsync({
        dose: (
          Number(p?.dose?.morning?.value ?? 0) +
          Number(p?.dose?.evening?.value ?? 0)
        ).toFixed(2),
        id: p.id,
      });
    });
  };

  function getPrescriptionCheckValue(id: string) {
    if (prescriptionCheckValue[id]?.value) {
      return `${prescriptionCheckValue[id]?.value} ${prescriptionCheckValue[id]?.unit}`;
    }

    return 'NA';
  }

  useEffect(() => {
    if (
      getPrescriptionDosageHistoryQuery.data?.data &&
      !getPrescriptionDosageHistoryQuery.isLoading
    ) {
      getCheckValue();
    }
    // eslint-disable-next-line
  }, [
    getPrescriptionDosageHistoryQuery.data?.data,
    getPrescriptionDosageHistoryQuery.isLoading,
  ]);

  useEffect(() => {
    if (isOpen) {
      queryClient.refetchQueries(
        QueryKeys.PrescriptionDosageHistory.listing({
          patientId,
          prescriptionId,
          doseIndex: prescription.currentDoseIndex,
        })
      );
    }

    //  eslint-disable-next-line
  }, [isOpen]);

  return (
    <Modal
      title={title}
      closeModal={() => {
        //
      }}
      open={isOpen}
      className="w-2/3">
      <div className="flex flex-col">
        <div className="light">
          <Table<DosageHistory>
            theme={ThemeName.Light}
            columns={[
              {
                title: 'Date',
                render: (rowData) =>
                  new GMTDate(rowData.updatedAt).getReadableDateInGMT(),
              },
              {
                title: 'Dose',
                render: (rowData) =>
                  `${(
                    rowData.dose.morning.value + rowData.dose.evening.value
                  ).toFixed(2)} ${
                    prescription.deliveryMethodAndSig.dose.unit
                  } `,
                classNames: 'text-center',
              },
              {
                title: '',
                render: (rowData) => getPrescriptionCheckValue(rowData.id),
                classNames: 'text-center',
              },
            ]}
            rowsData={[...(getPrescriptionDosageHistoryQuery.data?.data ?? [])]}
            noDataMessage="No record(s) found."
            loading={
              getPrescriptionDosageHistoryQuery.isLoading ||
              getPrescriptionCheckMutation.isLoading
            }
          />
        </div>
        <div className="mt-4 flex justify-end gap-6">
          <Button color="secondary" className="" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
}
