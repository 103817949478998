import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import { Chip } from 'components/common/Chip/Chip';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { SkeletonContent } from 'components/common/SkeletonContent/SkeletonContent';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { transformMedication } from 'pages/app/patients/[id]/prescriptions/prescribe/common';
import { PatientService } from 'services/patient';
import { PatientPrescriptionService } from 'services/prescription';
import { PrescribedObjectType } from 'types/prescriptionTypes';

type PrescriptionsBreakdownProps = {
  prescriptions: PrescribedObjectType[];
  isConcierge: boolean;
  patientId: string;
  billingCycle?: string;
  setBillingCycle: Dispatch<React.SetStateAction<string | undefined>>;
  deliveryMethod?: string;
  setDeliveryMethod: Dispatch<React.SetStateAction<string | undefined>>;
};
function PrescriptionInfo({
  prescription,
  isLoading,
}: {
  prescription: PrescribedObjectType;
  isLoading: boolean;
}) {
  return (
    <SkeletonContent
      layout={[
        {
          className: 'h-6 w-60 mt-6',
        },
      ]}
      isLoading={isLoading}>
      {() => (
        <div className={classNames('mt-6 mb-1 flex  items-center gap-3')}>
          {' '}
          <Typography color="primary" className="" variant="h4">
            {prescription?.standardizedName}, {prescription?.dtd}{' '}
            {prescription?.dtdUnit ?? 'ml'}
          </Typography>
          {!!prescription?.loe && (
            <Chip
              className=" border-orange-400 !py-[2px] text-orange-400"
              label="Early Fill"
            />
          )}
        </div>
      )}
    </SkeletonContent>
  );
}
export function PrescriptionsBreakdown({
  prescriptions,
  isConcierge,
  patientId,
  billingCycle,
  deliveryMethod,
  setBillingCycle,
  setDeliveryMethod,
}: PrescriptionsBreakdownProps) {
  const [prescriptionStadardizedName, setPrescriptionStadardizedName] =
    useState<{
      [x: string]: string;
    }>();
  const getStandardizedNameMutation = useMutation(
    (value: PrescribedObjectType) =>
      PatientPrescriptionService.getStandardizedName({
        patientId: patientId ?? '',
        medication: transformMedication(value as PrescribedObjectType),
      }),
    {
      onSuccess(data, variable) {
        setPrescriptionStadardizedName((pre) => ({
          ...pre,
          [variable.id as string]: data?.data.standardRepresentation,
        }));
      },
    }
  );

  const getPatientDetailsQuery = useQuery(
    QueryKeys.PatientDetails.item(patientId as string),
    () => PatientService.getPatientDetails(patientId || ''),
    { enabled: !!patientId }
  );

  const onShippingTypeChange = (value: string | boolean) => {
    if (value === deliveryMethod) {
      setDeliveryMethod(undefined);
    } else {
      setDeliveryMethod(value as string);
    }
  };

  const onBillingCycleChange = (value: string | boolean) => {
    if (value === billingCycle) {
      setBillingCycle(undefined);
    } else {
      setBillingCycle(value as string);
    }
  };
  const showBillingCycle = useCallback(() => {
    if (
      getPatientDetailsQuery.data?.data?.patientCompletedVisits === 0 ||
      getPatientDetailsQuery.data?.data?.patientCompletedVisits === null
    ) {
      return true;
    }

    return false;
  }, [getPatientDetailsQuery.data?.data?.patientCompletedVisits]);

  const showShippingType = useCallback(() => {
    if (getPatientDetailsQuery.data?.data?.practice.isShippingCostCovered) {
      return true;
    }

    return false;
  }, [getPatientDetailsQuery.data?.data?.practice.isShippingCostCovered]);
  function showCoveredUnderSubscription() {
    return prescriptions?.some(
      (p) =>
        p.coveredUnderSubscription?.toString().toLocaleLowerCase() === 'true'
    );
  }

  function showCoveredNotUnderSubscription() {
    return prescriptions?.some(
      (p) =>
        p.coveredUnderSubscription?.toString().toLocaleLowerCase() === 'false'
    );
  }

  function getStandardizedNameForHormone() {
    return prescriptions?.map((p) => getStandardizedNameMutation.mutate(p));
  }

  const missingStandardizedNames = useMemo(
    () => prescriptions?.some((p) => !p?.standardizedName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prescriptions]
  );

  useEffect(() => {
    if (missingStandardizedNames) {
      getStandardizedNameForHormone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptions, missingStandardizedNames]);

  return (
    <div className="flex w-full   flex-col  rounded bg-background-main p-6">
      <Typography variant="h4">Prescriptions</Typography>

      {isConcierge && (
        <div className="flex flex-col ">
          {showBillingCycle() && (
            <div className=" mt-6 flex flex-col">
              <Typography variant="h5">Select Billing Cycle</Typography>
              <div className="mt-2 flex justify-between">
                <div className="cursor-pointer rounded border border-zinc-600 bg-background-light ">
                  <RadioButton
                    id="annual"
                    className="p-3 pr-0"
                    onChange={onBillingCycleChange}
                    label="Annual"
                    checked={billingCycle === 'annual'}
                  />
                </div>
                <div className="cursor-pointer rounded border border-zinc-600 bg-background-light  ">
                  <RadioButton
                    className="p-3 pr-0"
                    id="monthly"
                    onChange={onBillingCycleChange}
                    label="Monthly"
                    checked={billingCycle === 'monthly'}
                  />
                </div>
              </div>
            </div>
          )}
          {showShippingType() && (
            <div className="mt-4 flex flex-col">
              <div className="mt-4 flex  flex-col gap-4">
                <div className=" w-full cursor-pointer rounded border border-zinc-600 bg-background-light ">
                  <RadioButton
                    fullWidth
                    className="w-full  p-3 pr-0"
                    id="expedited"
                    onChange={onShippingTypeChange}
                    label="Expedited (UPS Second Day Air)"
                    checked={deliveryMethod === 'expedited'}
                  />
                </div>{' '}
                <div className="cursor-pointer rounded border border-zinc-600 bg-background-light ">
                  <RadioButton
                    fullWidth
                    id="standard"
                    className="p-3 pr-0"
                    onChange={onShippingTypeChange}
                    label="Standard (USPS Priority Shipping)"
                    checked={deliveryMethod === 'standard'}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {showCoveredUnderSubscription() && (
        <div className="mt-6 rounded bg-background-light p-4">
          <Typography variant="h4">Rx(s) covered under subscription</Typography>
          {prescriptions
            ?.filter(
              (p) =>
                p.coveredUnderSubscription?.toString().toLocaleLowerCase() ===
                'true'
            )
            ?.map((p) => (
              <PrescriptionInfo
                isLoading={getStandardizedNameMutation.isLoading}
                prescription={{
                  ...p,
                  standardizedName: missingStandardizedNames
                    ? prescriptionStadardizedName?.[p?.id as string]
                    : p.standardizedName,
                }}
              />
            ))}
        </div>
      )}
      {showCoveredNotUnderSubscription() && (
        <div className="mt-6 rounded bg-background-light p-4">
          <Typography variant="h4">
            {' '}
            Rx(s){' '}
            <Typography className="mx-1" color="error" variant="h4">
              NOT
            </Typography>{' '}
            covered under subscription
          </Typography>
          {prescriptions
            ?.filter(
              (p) =>
                p.coveredUnderSubscription?.toString().toLocaleLowerCase() ===
                'false'
            )
            ?.map((p) => (
              <PrescriptionInfo
                isLoading={getStandardizedNameMutation.isLoading}
                prescription={{
                  ...p,
                  standardizedName: missingStandardizedNames
                    ? prescriptionStadardizedName?.[p?.id as string]
                    : p.standardizedName,
                }}
              />
            ))}
        </div>
      )}
    </div>
  );
}
