import React, { useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Tooltip } from 'react-tooltip';

import { Button } from 'components/common/Button/Button';
import { Table } from 'components/common/Table/Table';
import { Typography } from 'components/common/Typography/Typography';
import { InvitePatientModal } from 'components/patient/InvitePatientModal/InvitePatientModal';
import { QueryKeys } from 'constants/query-keys';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { useSearchParamsPagination } from 'hooks/useSearchParamsPagination';
import { PatientService } from 'services/patient';
import { getReadableDate } from 'utils/common';

export function InvitedPatients() {
  const queryClient = useQueryClient();
  const [openInvitePatientModal, setOpenInvitePatientModal] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
  const [resendInvite, setResendInvite] = useState<
    { id: string; email: string } | undefined
  >();
  const { page, setPage } = useSearchParamsPagination({
    key: 'invited_pt_page',
  });
  const getInvitedPatientsQuery = useQuery(
    QueryKeys.InvitedPatients.listing({ page, size: recordsPerPage }),
    () => PatientService.getInvitedPatients({ page, size: recordsPerPage })
  );

  const deleteInviteMutation = useMutationWithToast(
    PatientService.deleteInvite
  );

  function deleteInvite(id: string) {
    deleteInviteMutation.mutate(
      { id },
      {
        onSuccess: () => {
          queryClient.refetchQueries(QueryKeys.InvitedPatients.listings());
        },
      }
    );
  }

  return (
    <div>
      <span className="mb-2 flex items-center  justify-end  text-base">
        <Button
          className="!py-2.5"
          onClick={() => setOpenInvitePatientModal(true)}>
          Invite Patient
        </Button>
      </span>
      <Table
        loading={getInvitedPatientsQuery?.isLoading}
        rowsData={getInvitedPatientsQuery?.data?.data?.items || []}
        columns={[
          {
            title: 'ID',
            render: (rowData) => rowData.id,
          },
          {
            title: 'Email',
            render: (rowData) => (
              <>
                <Typography
                  variant="subtitle2"
                  ellipsis
                  className="relative max-w-[20rem] overflow-hidden "
                  data-tooltip-id={rowData?.id}>
                  {rowData?.email}
                </Typography>{' '}
                <Tooltip
                  style={{
                    color: 'black',
                    fontFamily: 'Verdana',
                    zIndex: 10,
                  }}
                  className="bg-background-contrastText text-md text-background-dark"
                  id={rowData?.id}>
                  {rowData?.email}
                </Tooltip>
              </>
            ),
          },
          {
            title: 'Date',
            render: (rowData) => getReadableDate(rowData?.createdAt),
            classNames: 'text-center',
          },
          {
            title: '',
            render: (rowData) => (
              <div className="flex">
                <Button
                  className="!text-base"
                  size="small"
                  onClick={() => {
                    setOpenInvitePatientModal(true);
                    setResendInvite({ id: rowData?.id, email: rowData?.email });
                  }}>
                  Resend
                </Button>
                <span className="mx-2" />
                <Button
                  className="!text-base"
                  loading={
                    deleteInviteMutation.isLoading &&
                    deleteInviteMutation.variables?.id === rowData?.id
                  }
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => deleteInvite(rowData?.id)}>
                  Delete
                </Button>
              </div>
            ),
          },
        ]}
        noDataMessage=" You currently have no pending invitation requests"
        pagination
        onPageChange={setPage}
        page={page}
        totalRecords={getInvitedPatientsQuery?.data?.data?.total || 0}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        isPageSizeSelectionAvailable
      />

      {openInvitePatientModal && (
        <InvitePatientModal
          openModal={openInvitePatientModal}
          resendInviteData={resendInvite}
          onModalClose={() => {
            setOpenInvitePatientModal(false);
            setTimeout(() => setResendInvite(undefined), 300);
          }}
          onInviteResendSuccess={() => {
            setOpenInvitePatientModal(false);
            setTimeout(() => setResendInvite(undefined), 300);
          }}
        />
      )}
    </div>
  );
}
